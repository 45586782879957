<template>
    <section>
        <div class="text-center " style="margin-top: 2%;">
      <img :src=$store.state.logo style="width: 100px;border-radius: 50%; aspect-ratio: 1/1;object-fit: contain;">
      <h4>{{ form.name }}</h4>
     
      
      <span style="color: #818181;" dir="ltr">{{ phone }}</span>
    <br>
      <span style="color: #818181;">{{ form.email }}</span>
    
    
      
    
    </div>
    <div  id="div_but_order">
     
<router-link :to="{
              name: 'myOrders',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
                  }"
              class="order_but"> 
  <i class="fa fa-shopping-cart" ></i> {{ $t('myOrders') }}
</router-link>

<router-link class="order_but" 
:to="{
              name: 'favorites',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
                  }">
  <i class="fa fa-heart-o"></i> {{$t('favorites')}}
</router-link>

<router-link class="order_but" 
:to="{
              name: 'myAddresses',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
                  }"
                  >
  <i class="fa fa-map-marker">
</i> {{ $t('myAddresses') }}</router-link>
<router-link  
:to="{
              name: 'profile',
              params: { storeName: this.$store.storeName,lang:this.$i18n.locale },
                  }"

 class="order_but"><i class="fa fa-pencil"></i> {{ $t('Edit Profile') }}</router-link>
</div>
    </section>
    </template>

    
<script>
  
import Form from "vform";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";
import router from "@/router";

export default {
  components: {
    Vodal,
    CodeInput,
    
  },
  data: function() {
    return {
      //DBitems: null,
      forgetVodal: false,
      codeVodal: false,
      newPassVodal: false,

      errorMessageVodal: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      finalcode: null,
      phone:null,
      errorMessage: "",
      form: new Form({
        email: null,
        name: null,
        phone:null,
      }),
      show: false,
      show2: false,
    };
  },

  created() {
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone;
       
        console.log('******* ',response.data.data)
      })
      
      if(this.$i18n.locale=='ar')
    {
    document.title = this.$store.state.title_ar;
    document.getElementById("description_meta").content=this.$store.state.description_ar;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
    }
    else{
      document.title = this.$store.state.title_en;
    document.getElementById("description_meta").content=this.$store.state.description_en;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
    }
  },
  methods: {
    logOut() {
    this.loading = true;
    this.axios.get(
      this.$store.state.base_api_url + this.$store.storeName + "/logout",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    );
    //console.group("hiiiiii")
    console.log(
      "logout:" +
        //this.$localStorage.id() +
        this.$localStorage.get(this.$store.storeName)
    );
    //this.$localStorage.remove("userToken");
    this.show=false;
    let AllCookies = this.$cookies.keys();
    for (let i = 0; i < AllCookies.length; i++) {
      console.log('hhhhhhhhhhhhhhh111');
      if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
        this.$cookies.remove(console.log(AllCookies[i]));
      
      }
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

    }
    console.log(AllCookies);
    /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
    this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
    }*/
    this.$localStorage.remove(this.$store.storeName);
    window.location.reload("/");
    this.loading = false;
  },   
},
  watch:{
    langchange()
    {
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone.substr(4);
        console.log('phone ' +this.phone);
        //console.log(this.form)
      })
     
    }
  }
};
</script>