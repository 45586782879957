<template>
<div class="lg:w-1/4 md:w-1/3 md:px-3 sidebar">
                        <div class="relative md:-mt-48 -mt-32">
                            <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                                <div class="profile-pic text-center mb-5">
                                    
                                    <div>
                                        <div class="relative h-28 w-28 mx-auto">
                                            <img src="theme8/images/user_profile.png" 
                                            class="rounded-full shadow dark:shadow-gray-800
                                             ring-4 ring-slate-50 dark:ring-slate-800" 
                                             id="profile-image" alt="">
                                            <label class="absolute inset-0 cursor-pointer" 
                                            for="pro-img"></label>
                                        </div>

                                        <div class="mt-4">
                                            <h5 class="text-lg font-semibold">{{ form.name }}</h5>
                                            <p class="text-slate-400">{{ form.email }}</p>
                                            <p class="text-slate-400">{{ form.phone }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-t border-gray-100 dark:border-gray-700">
                                    <ul class="list-none sidebar-nav mb-0 pb-0" 
                                    id="navmenu-nav">
                                        <li class="navbar-item account-menu">
                                            <router-link 
                                            :to="{
                               name: 'profile',
                              params: { storeName: this.$store.storeName ,lang: $i18n.locale},
                                   }"
                                            class="navbar-link text-slate-400 flex items-center py-2 rounded">
                                                <span class="me-2 mb-0">
                                                    <i v-html="user"
                                                     class="size-4"></i></span>
                                                <h6 class="mb-0 font-medium">{{$t('Account')}}</h6>
                                            </router-link>
                                        </li>
    
    
                                        <li class="navbar-item account-menu">
                                            <router-link 
                                            :to="{
                                           name: 'myOrders',
                                           params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                                 }"
                                             class="navbar-link text-slate-400 flex items-center py-2 rounded">
                                                <span class="me-2 mb-0">
                                                <i v-html="shopping_cart" class="size-4"></i></span>
                                                <h6 class="mb-0 font-medium">{{$t('myOrders')}}</h6>
                                            </router-link>
                                        </li>
    
                                        <li class="navbar-item account-menu">
                                            <router-link 
                                            
                                            :to="{
                                           name: 'myAddresses',
                                           params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                                 }"

                                            class="navbar-link text-slate-400 flex items-center py-2 rounded">
                                                <span class="me-2 mb-0">
                                                    <i v-html="map_pin"
                                                    class="size-4"></i></span>
                                                <h6 class="mb-0 font-medium">{{ $t('myAddresses') }}</h6>
                                            </router-link>
                                        </li>
    
                                        <li class="navbar-item account-menu">
                                            <router-link 
                                            :to="{
                                           name: 'notifications',
                                           params: { storeName: this.$store.storeName ,lang: $i18n.locale},
                                                 }"

                                            
                                             class="navbar-link text-slate-400 flex items-center py-2 rounded">
                                                <span class="me-2 mb-0"><i v-html="bell" class="size-4"></i></span>
                                                <h6 class="mb-0 font-medium">{{$t('Notifications')}}</h6>
                                            </router-link>
                                        </li>
    
                                       
    
                                        <li class="navbar-item account-menu" @click="show = true" >
                                            <a 
                                            @click="this.show = true"
                                            class="navbar-link text-slate-400 flex items-center py-2 rounded">
                                                <span class="me-2 mb-0"><i v-html="log_out" class="size-4"></i></span>
                                                <h6 class="mb-0 font-medium">{{$t('logOut')}}</h6>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <Transition>
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmlogout") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="logOut()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>


     
    </Transition>
                    </div>
</template>

<script>
  
import Form from "vform";
import side_bar from "../components/sidebar_profile.vue";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";

export default {
  components: {
    Vodal,
    CodeInput,
    side_bar
    
  },
  data: function() {
    return {
      //DBitems: null,
      forgetVodal: false,
      codeVodal: false,
      newPassVodal: false,

      errorMessageVodal: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      finalcode: null,
      phone:null,
      errorMessage: "",
      form: new Form({
        email: null,
        name: null,
        phone:null,
      }),
      show: false,
      show2: false,
    };
  },
  computed: {
 
  shopping_cart: function () {
    return feather.toSvg('shopping-cart')
  },
 
  user: function () {
    return feather.toSvg('user')
  },
 
  log_out: function () {
    return feather.toSvg('log-out')
  },
  help_circle: function () {
    return feather.toSvg('help-circle')
  },
  map_pin: function () {
    return feather.toSvg('map-pin')
  },
  bell: function () {
    return feather.toSvg('bell')
  },
  //map_pin
  
},

  created() {
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone.substr(4);
        console.log('phone ' +this.phone);
        this.$localStorage.set(
              this.$store.user_phone,
              data.data.phone );
        console.log('******* ',response.data.data)
      })
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "/" ;
        }
      });
      if(this.$i18n.locale=='ar')
    {
    document.title = this.$store.state.title_ar;
    document.getElementById("description_meta").content=this.$store.state.description_ar;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
    }
    else{
      document.title = this.$store.state.title_en;
    document.getElementById("description_meta").content=this.$store.state.description_en;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
    }
  },
  methods: {
    logOut() {
    this.loading = true;
    this.axios.get(
      this.$store.state.base_api_url + this.$store.storeName + "/logout",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization:
            "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    );
    //console.group("hiiiiii")
    console.log(
      "logout:" +
        //this.$localStorage.id() +
        this.$localStorage.get(this.$store.storeName)
    );
    //this.$localStorage.remove("userToken");
    this.show=false;
    let AllCookies = this.$cookies.keys();
    for (let i = 0; i < AllCookies.length; i++) {
      console.log('hhhhhhhhhhhhhhh111');
      if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
        this.$cookies.remove(console.log(AllCookies[i]));
      
      }
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

    }
    console.log(AllCookies);
    /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
    this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
    }*/
    this.$localStorage.remove(this.$store.storeName);
    window.location.reload("/");
    this.loading = false;
  },
  
    
},
  watch:{
    langchange()
    {
      this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.form.email = response.data.data.email;
        this.form.name = response.data.data.name;
        this.phone=response.data.data.phone.substr(4);
        console.log('phone ' +this.phone);
        //console.log(this.form)
      })
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href =
            "/" ;
        }
      });
    }
  }
};
</script>