var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"relative py-16"},[_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid items-end md:grid-cols-2 mb-6"},[_c('div',{staticClass:"md:text-start text-center"},[_c('h5',{staticClass:"font-semibold text-3xl leading-normal mb-4"},[_vm._v(" "+_vm._s(_vm.name))]),_c('p',{staticClass:"text-slate-400 max-w-xl"},[_vm._v(_vm._s(_vm.$t('Shop the latest products from the most popular items')))])]),_c('div',{staticClass:"md:text-end hidden md:block"},[_c('router-link',{staticClass:"text-slate-400 hover:text-orange-500",attrs:{"to":{
                              name: 'section',
                              params: { storeName: _vm.$store.storeName,
                               id: this.id  ,
                               name:this.name,
                               lang: _vm.$i18n.locale},
                                  }}},[_vm._v(_vm._s(_vm.$t('See More Items'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)])]),_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6"},_vm._l((this.products),function(item,index){return _c('product',{key:index,attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id,"rate":item.rate,"count_view":item.count_views,"is_option":item.is_option,"is_offer":item.is_offer,"unit":item.unit,"new":item.is_new,"fetuered":item.is_fetured,"discount":item.discount,"qnt":item.qnt,"min_qnt":item.min_qnt,"max_qnt":item.max_qnt}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }