import Vue from 'vue'
import VueRouter from 'vue-router'
//theme 1

import HomeView from '../views/Home.vue'

import MyOrdersView from '../views/MyOrders.vue'
import MyAddressesView from '../views/MyAddresses.vue'
import ProfileView from '../views/Profile.vue'
import SectionsView from '../views/Sections.vue'
import SectionView from '../views/Section.vue'
import SearchView from '../views/Search.vue'
import OffersView from '../views/Offers.vue'
import BrandsView from '../views/Brands.vue'
import BestSellerView from '../views/BestSeller.vue';
import LatestProductsView from '../views/LatestProducts.vue';
import FeaturedProductsView from '../views/FeaturedProducts.vue';

import ProductView from '../views/Product.vue';
import BrandView from '../views/Brand.vue';
import ShoppingCartView from '../views/ShoppingCart.vue';
import ConfirmOrderView from '../views/ConfirmOrder.vue';
import RegisterView from '../views/Register.vue';
import ActivateCodeView from '../views/ActivateCode.vue';
import LoginView from '../views/Login.vue';
import LoginViewPhone from '../views/Loginphone.vue';
import LoginViewPhoneSms from '../views/Loginphone2.vue';
import AboutView from '../views/About.vue';
import PrivacyView from '../views/Privacy.vue';
import ContactUsView from '../views/ContactUs.vue';
import FavoritesView from '../views/Favorites.vue';
import InvoiceView from '../views/Invoice.vue';
import NotificationsView from '../views/Notifications.vue';

//theme 2
import HomeView2 from '../Them2/views/Home.vue'
import MyOrdersView2 from '../Them2/views/MyOrders.vue'
import MyAddressesView2 from '../Them2/views/MyAddresses.vue'
import ProfileView2 from '../Them2/views/Profile.vue'
import SectionsView2 from '../Them2/views/Sections.vue'
import SectionView2 from '../Them2/views/Section.vue'
import SearchView2 from '../Them2/views/Search.vue'
import OffersView2 from '../Them2/views/Offers.vue';
import BrandsView2 from '../Them2/views/Brands.vue'
import BestSellerView2 from '../Them2/views/BestSeller.vue';
import LatestProductsView2 from '../Them2/views/LatestProducts.vue';
import FeaturedProductsView2 from '../Them2/views/FeaturedProducts.vue';

import ProductView2 from '../Them2/views/Product.vue';
import BrandView2 from '../Them2/views/Brand.vue';
import ShoppingCartView2 from '../Them2/views/ShoppingCart.vue';
import ConfirmOrderView2 from '../Them2/views/ConfirmOrder.vue';
import RegisterView2 from '../Them2/views/Register.vue';
import ActivateCodeView2 from '../Them2/views/ActivateCode.vue';
import LoginView2 from '../Them2/views/Login.vue';
import LoginViewPhone2 from '../Them2/views/Loginphone.vue';
import LoginViewPhoneSms2 from '../Them2/views/Loginphone2.vue';
import AboutView2 from '../Them2/views/About.vue';
import PrivacyView2 from '../Them2/views/Privacy.vue';
import ContactUsView2 from '../Them2/views/ContactUs.vue';
import FavoritesView2 from '../Them2/views/Favorites.vue';
import InvoiceView2 from '../Them2/views/Invoice.vue';
import NotificationsView2 from '../Them2/views/Notifications.vue';


//theme 3
import HomeView3 from '../Them3/views/Home.vue'
import ProductView3 from '../Them3/views/Product.vue';
import SectionsView3 from '../Them3/views/Sections.vue'
import AboutView3 from '../Them3/views/About.vue';
import PrivacyView3 from '../Them3/views/Privacy.vue';
import ContactUsView3 from '../Them3/views/ContactUs.vue';
import SectionView3 from '../Them3/views/Section.vue'
import LoginView3 from '../Them3/views/Login.vue';
import LoginViewPhone3 from '../Them3/views/Loginphone.vue';
import LoginViewPhoneSms3 from '../Them3/views/Loginphone2.vue';
import RegisterView3 from '../Them3/views/Register.vue';
import ActivateCodeView3 from '../Them3/views/ActivateCode.vue';
import MyOrdersView3 from '../Them3/views/MyOrders.vue'
import MyAddressesView3 from '../Them3/views/MyAddresses.vue'
import ProfileView3 from '../Them3/views/Profile.vue'
import FavoritesView3 from '../Them3/views/Favorites.vue';
import InvoiceView3 from '../Them3/views/Invoice.vue';
import NotificationsView3 from '../Them3/views/Notifications.vue';
import ConfirmOrderView3 from '../Them3/views/ConfirmOrder.vue';
import BrandsView3 from '../Them3/views/Brands.vue'
import BestSellerView3 from '../Them3/views/BestSeller.vue';
import BrandView3 from '../Them3/views/Brand.vue';
import OffersView3 from '../Them3/views/Offers.vue';
import SearchView3 from '../Them3/views/Search.vue'


//theme5

import theme5_home from '../theme5/views/home.vue';

import theme5_page from '../theme5/views/page.vue';
import theme5_contactUs from '../theme5/views/ContactUs.vue';
import theme5_sections from '../theme5/views/sections.vue';
import theme5_section from '../theme5/views/section.vue';
import theme5_product from '../theme5/views/product.vue';
import theme5_products from '../theme5/views/products.vue';
import theme5_checkout from '../theme5/views/check_out.vue';
import theme5_shopping_cart from '../theme5/views/shopping_cart.vue';
import theme5_register from '../theme5/views/Register.vue';
import theme5_login from '../theme5/views/login.vue';
import theme5_LoginViewPhone from '../theme5/views/Loginphone.vue';
import theme5_LoginViewPhoneSms from '../theme5/views/Loginphone2.vue';
import theme5_ActivateCodeView from '../theme5/views/ActivateCode.vue';
import theme5_InvoiceView from '../theme5/views/Invoice.vue';

import theme5_profile from '../theme5/views/profile.vue';
import theme5_MyAddressesView from '../theme5/views/profile_address.vue';
import theme5_MyOrdersView from '../theme5/views/profile_orders.vue';
import theme5_favourite from '../theme5/views/favourite.vue';
import theme5_search from '../theme5/views/Search.vue';

import theme5_brand from '../theme5/views/products_brand.vue';

//theme8
import theme8_home from '../theme8/views/home.vue';
import theme8_sections from '../theme8/views/sections.vue';
import theme8_products from '../theme8/views/products.vue';
import BrandsView8 from '../theme8/views/brands.vue';
import AboutView8 from '../theme8/views/about.vue';
import ContactUsView8 from '../theme8/views/contactUs.vue';
import productView8 from '../theme8/views/product.vue';
import BrandView8 from '../theme8/views/brand_products.vue';
import PrivacyView8 from '../theme8/views/privacy.vue';
import theme8_section from '../theme8/views/section.vue';
import LoginView8 from '../theme8/views/login.vue';
import LoginViewPhone8 from '../theme8/views/Loginphone.vue';
import LoginViewPhoneSms8 from '../theme8/views/Loginphone2.vue';
import FavoritesView8 from '../theme8/views/favourite.vue';
import RegisterView8 from '../theme8/views/register.vue';
import ActivateCodeView8 from '../theme8/views/activeCode.vue';
import ProfileView8 from '../theme8/views/profile.vue'
import ShoppingCartView8 from '../theme8/views/cart.vue';
import ConfirmOrderView8 from '../theme8/views/confirmOrder.vue';
import MyAddressView8 from '../theme8/views/my_address.vue';
import SearchView8 from '../theme8/views/search.vue';
import NotificationsView8 from '../theme8/views/myNotification.vue';
import InvoiceView8 from '../theme8/views/order.vue';
import MyOrdersView8 from '../theme8/views/myOrders.vue';
import OffersView8 from '../theme8/views/products.vue';
//theme10
import theme10_home from '../theme10/views/home.vue';
import theme10_sections from '../theme10/views/sections.vue';
import theme10_section from '../theme10/views/sectionCategories.vue';
import LoginView10 from     '../theme10/views/Login.vue';
import LoginViewPhone10 from '../theme10/views/LoginPhone.vue';
import LoginViewPhoneSms10 from '../theme10/views/LoginPhone2.vue';
import BrandsView10 from '../theme10/views/brands.vue'
import BestSellerView10 from '../theme10/views/products.vue';
import LatestProductsView10 from '../theme10/views/latest_products.vue';
import FeaturedProductsView10 from '../theme10/views/feautred_products.vue';
import AboutView10 from '../theme10/views/about.vue';
import ContactUsView10 from '../theme10/views/contactus.vue'
import productView10 from '../theme10/views/product.vue'
import RegisterView10 from '../theme10/views/register.vue';
import ActivateCodeView10 from '../theme10/views/activeCode.vue';
import MyOrdersView10 from '../theme10/views/myOrders.vue'
import MyAddressView10 from '../theme10/views/myAddresses.vue'
import ProfileView10 from '../theme10/views/profile.vue'
import ConfirmOrderView10 from '../theme10/views/confirmOrder.vue'
import InvoiceView10 from  '../theme10/views/order.vue'
import FavoritesView10 from '../theme10/views/favourite'
import OffersView10 from '../theme10/views/products.vue'
import ShoppingCartView10  from '../theme10/views/cart.vue'
import SearchView10 from '../theme10/views/search.vue'
import NotificationsView10  from '../theme10/views/notifications.vue'
import PrivacyView10 from '../theme10/views/privacy.vue'
import theme10_category from '../theme10/views/category.vue'


Vue.use(VueRouter)
const routes = [{
            path: '/:lang(ar|en)?',
            name: 'home1',
            components: {
                default: HomeView,
                theme2: HomeView2,
                theme3: HomeView3,
                theme5: theme5_home,
                theme8: theme8_home,
                theme10: theme10_home,
            }
        },
        {
            path: '/:lang(ar|en)?/',
            name: 'home',
            components: {
                default: HomeView,
                theme2: HomeView2,
                theme3: HomeView3,
                theme5: theme5_home,
                theme8: theme8_home,
                theme10: theme10_home,
            }
        },
        {
            path: '/:lang(ar|en)?/register',
            name: 'register',
            components: {
                default: RegisterView,
                theme2: RegisterView2,
                theme3: RegisterView3,
                theme5: theme5_register,
                theme8: RegisterView8,
                theme10: RegisterView10
            }
        },
        {
            path: '/:lang(ar|en)?/activateCode',
            name: 'activateCode',
            components: {
                default: ActivateCodeView,
                theme2: ActivateCodeView2,
                theme3: ActivateCodeView3,
                theme5: theme5_ActivateCodeView,
                theme8: ActivateCodeView8,
                theme10: ActivateCodeView10,

            },
            props: {
                default: true,
                theme2: true,
                theme3: true,
                theme5: true,
                theme8:true,
                theme10:true
            },

        },
        {
            path: '/:lang(ar|en)?/login',
            name: 'login',
            components: {
                default: LoginView,
                theme2: LoginView2,
                theme3: LoginView3,
                theme5: theme5_login,
                theme8: LoginView8,
                theme10: LoginView10,
            }
        },
        {
            path: '/:lang(ar|en)?/login',
            name: 'loginPhone',
            components: {
                default: LoginViewPhone,
                theme2: LoginViewPhone2,
                theme3: LoginViewPhone3,
                theme5: theme5_LoginViewPhone,
                theme8: LoginViewPhone8,
                theme10: LoginViewPhone10,
            }
        },
        {
            path: '/:lang(ar|en)?/login',
            name: 'loginPhonesms',
            components: {
                default: LoginViewPhoneSms,
                theme2: LoginViewPhoneSms2,
                theme3: LoginViewPhoneSms3,
                theme5: theme5_LoginViewPhoneSms,
                theme8: LoginViewPhoneSms8,
                theme10: LoginViewPhoneSms10,
            }
        },
        {
            path: '/:lang(ar|en)?/about',
            name: 'about',
            components: {
                default: AboutView,
                theme2: AboutView2,
                theme3: AboutView3,
                theme8: AboutView8,
                theme10: AboutView10,
            }
        },
        {
            path: '/:lang(ar|en)?/page/:page',
            name: 'page',
            components: {
                default: theme5_page,
                theme5:theme5_page
              
            }
        },
        {
            path: '/:lang(ar|en)?/privacy',
            name: 'privacy',
            components: {
                default: PrivacyView,
                theme2: PrivacyView2,
                theme3: PrivacyView3,
                theme8:PrivacyView8,
                theme10:PrivacyView10
            }
        },
        {
            path: '/:lang(ar|en)?/contactUs',
            name: 'contactUs',
            components: {
                default: ContactUsView,
                theme2: ContactUsView2,
                theme3: ContactUsView3,
                theme5: theme5_contactUs,
                theme8: ContactUsView8,
                theme10: ContactUsView10,
                
            }
        },
        {
            path: '/:lang(ar|en)?/myOrders',
            name: 'myOrders',
            components: {
                default: MyOrdersView,
                theme2: MyOrdersView2,
                theme3: MyOrdersView3,
                theme5: theme5_MyOrdersView,
                theme8: MyOrdersView8,
                theme10: MyOrdersView10,

            }
        },
        
        {
            path: '/:lang(ar|en)?/myOrders/failed',
            name: 'myOrdersFailed',
            components: {
                default: ConfirmOrderView,
                theme2: ConfirmOrderView2,
                theme3: ConfirmOrderView3,
                theme5: theme5_checkout,
                theme8: ConfirmOrderView8,
                theme10: ConfirmOrderView10,
            }
        },
        {
            path: '/:lang(ar|en)?/myOrders/success',
            name: 'myOrdersSuccess',
            components: {
                default: MyOrdersView,
                theme2: MyOrdersView2,
                theme3: MyOrdersView3,
                theme5: theme5_MyOrdersView,
                theme8: MyOrdersView8,
                theme10: MyOrdersView10,
            }
        },
        
        {
            path: '/:lang(ar|en)?/invoice/:id',
            name: 'invoice',
            components: {
                default: InvoiceView,
                theme2: InvoiceView2,
                theme3: InvoiceView3,
                theme5: theme5_InvoiceView,
                theme8: InvoiceView8,
                theme10: InvoiceView10,
                
        
            }
        },
        {
            path: '/:lang(ar|en)?/myAddresses',
            name: 'myAddresses',
            components: {
                default: MyAddressesView,
                theme2: MyAddressesView2,
                theme3: MyAddressesView3,
                theme5: theme5_MyAddressesView,
                theme8: MyAddressView8,
                theme10: MyAddressView10
            }
        },
        {
            path: '/:lang(ar|en)?/favorites',
            name: 'favorites',
            components: {
                default: FavoritesView,
                theme2: FavoritesView2,
                theme3: FavoritesView3,
                theme5: theme5_favourite,
                theme8: FavoritesView8,
                theme10: FavoritesView10,
            }
        },
        {
            path: '/:lang(ar|en)?/profile',
            name: 'profile',
            components: {
                default: ProfileView,
                theme2: ProfileView2,
                theme3: ProfileView3,
                theme5: theme5_profile,
                theme8: ProfileView8,
                theme10: ProfileView10

            }
        },
        {
            path: '/:lang(ar|en)?/sections',
            name: 'sections',
            components: {
                default: SectionsView,
                theme2: SectionsView2,
                theme3: SectionsView3,
                theme5:theme5_sections,
                theme8:theme8_sections,
                theme10:theme10_sections,
            },
        },
        {
            path: '/:lang(ar|en)?/section/:id/:name',
            name: 'section',
            components: {
                default: SectionView,
                theme2: SectionView2,
                theme3: SectionView3,
                theme5 : theme5_section,
                theme8 : theme8_section,
                theme10 : theme10_section
            },
            props: {
                default: false,
                theme2: true,
                theme5: true,
                theme8: true
            },
        },
        {
            path: '/:lang(ar|en)?/category/:id/:name',
            name: 'category',
            components: {
                default: theme10_category,
                theme2: theme10_category,
                theme3: theme10_category,
                theme5 : theme10_category,
                theme8 : theme10_category,
                theme10 : theme10_category
            },
            props: {
                default: false,
                theme2: true,
                theme5: true,
                theme8: true,
                theme10: true
            },
        },
        
        {
            path: '/:lang(ar|en)?/offers',
            name: 'offers',
            components: {
                default: OffersView,
                theme2: OffersView2,
                theme3: OffersView3,
                theme8: OffersView8,
                theme10: OffersView10
            }
        },
        {
            path: '/:lang(ar|en)?/brands',
            name: 'brands',
            components: {
                default: BrandsView,
                theme2: BrandsView2,
                theme3: BrandsView3,
                theme8: BrandsView8,
                theme10: BrandsView10
            }
        },
        {
            path: "/:lang(ar|en)?/bestSeller",
            name: 'bestSeller',
            components: {
                default: BestSellerView,
                theme2: BestSellerView2,
                theme3: BestSellerView3,
                theme8: theme8_products,
                theme10: BestSellerView10,
            }
        },

        {
            path: '/:lang(ar|en)?/products',
            name: 'products',
            components: {
                default: BestSellerView,
                theme2: BestSellerView2,
                theme3: BestSellerView3,
                theme5: theme5_products,
                theme8: theme8_products,
                theme10: BestSellerView10,
            }
        },

        {
            path: "/:lang(ar|en)?/latestProducts",
            name: 'latestProducts',
            components: {
                default: LatestProductsView,
                theme2: LatestProductsView2,
                theme8: theme8_products,
                theme10: LatestProductsView10,
            }
        },

        {
            path: "/FeaturedProducts",
            name: 'FeaturedProducts',
            components: {
                default: FeaturedProductsView,
                theme2: FeaturedProductsView2,
                theme8: theme8_products,
                theme10: FeaturedProductsView10,
            }
        },
        
        {
            path: "/:lang(ar|en)?/product/:id/:name",
            name: 'product',
            components: {
                default: ProductView,
                theme2: ProductView2,
                theme3: ProductView3,
                theme5: theme5_product,
                theme8: productView8,
                theme10: productView10,
            }
        },
        {
            path: "/:lang(ar|en)?/brand/:id/:name",
            name: 'brand',
            components: {
                default: BrandView,
                theme2: BrandView2,
                theme3: BrandView3,
                theme5:theme5_brand,
                theme8:BrandView8

            }
        },
        {
            path: "/:lang(ar|en)?/shoppingCart",
            name: 'shoppingCart',
            components: {
                default: ShoppingCartView,
                theme2: ShoppingCartView2,
                theme5:theme5_shopping_cart,
                theme8: ShoppingCartView8,
                theme10: ShoppingCartView10,
            }
        },

        {
            path: "/:lang(ar|en)?/shopping_cart",
            name: 'shopping_cart',
            components: {
                default: ShoppingCartView,
                theme2: ShoppingCartView2,
                theme5:theme5_shopping_cart,
                theme8: ShoppingCartView8,
                theme10: ShoppingCartView10,
            }
        },
        {
            path: "/:lang(ar|en)?/confirmOrder",
            name: 'confirmOrder',
            components: {
                default: ConfirmOrderView,
                theme2: ConfirmOrderView2,
                theme3: ConfirmOrderView3,
                theme8: ConfirmOrderView8,
                theme10: ConfirmOrderView10
            }
        },

        {
            path: '/:lang(ar|en)?/checkout',
            name: 'checkout',
            components: {
                default: ConfirmOrderView,
                theme2: ConfirmOrderView2,
                theme3: ConfirmOrderView3,
                theme5 :theme5_checkout,
                theme8: ConfirmOrderView8,
                theme10: ConfirmOrderView10
              
            }
        },
        {
            path: "/:lang(ar|en)?/Search/:word",
            name: 'search',
            components: {
                default: SearchView,
                theme2: SearchView2,
                theme3: SearchView3,
                theme5:theme5_search,
                theme8: SearchView8,
                theme10: SearchView10,
            }
        },
        {
            path: "/:lang(ar|en)?/notifications",
            name: 'notifications',
            components: {
                default: NotificationsView,
                theme2: NotificationsView2,
                theme3: NotificationsView3,
                theme8: NotificationsView8,
                theme10: NotificationsView10
            }
        },
    ]
    //const storeName = 'marqat'

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    

})


export default router