<template>
    <!-- Loader End -->
    <section 
     style="margin-top:8% !important"
    class="md:h-screen py-36 flex items-center bg-orange-500/10 
    dark:bg-orange-500/20 bg-[url('../../theme8/images/hero/bg-shape.png')]
     bg-center bg-no-repeat bg-cover">
           <div class="container relative">
               <div class="grid grid-cols-1">
                   <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700
                    bg-white dark:bg-slate-900">
                       <div class="grid md:grid-cols-2 grid-cols-1 items-center">
                           <div class="relative md:shrink-0 login_img">
                               <img class="lg:h-full h-full w-full
                                 object-cover md:h-[34rem]" 
                               :src=this.$store.state.img_login
                                  alt="">
                                  
                           </div>

                           <div class="p-8 lg:px-20">
                             <div class="text-center">
                                   <router-link  :to="{
                             name: 'home',
                            params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                    }"
                                    >
                                       <img :src="this.$store.state.logo"  
                                       :alt="this.$store.storeName"
                                       class="mx-auto block dark:hidden logo_login" 
                                       >
                                       <img 
                                       :src="this.$store.state.logo" 
                                        :alt="this.$store.storeName" 
                                       class="mx-auto hidden dark:block logo_login" >
                                   </router-link>
                               </div>
                               <div v-if="registerUserInfo">
                              <user-info 
                              :phone="phone" 
                              :email="email">
                              </user-info>
                                 </div>


                               <div v-else>
        <p style="text-align:center" >{{ $t("EnterCode") }}</p>
        <br>
        <div dir="ltr"  class="center-text text-center">
          <CodeInput
            :loading="false"
            required
            class="input"
            @change="onChange"
            @complete="onComplete"
          />
        </div>
        <p
          style="margin-top: 15px; margin-bottom: 0;text-align:center"
          v-if="$i18n.locale == 'en'"
        >
          {{ countDown }} second
        </p>
        <p style="margin-top: 15px; margin-bottom: 0 ;text-align:center" v-else>
          {{ countDown }} ثانية
        </p>
        <div
          v-if="validateMessage != ''"
          style="text-align: center; margin-top: 10px; margin-bottom: 0"
        >
          <span style="color: red; margin: 0">{{ validateMessage }}</span>
        </div>
        <div
          v-if="validateMessage1 != ''"
          style="text-align: center; margin-top: 10px; margin-bottom: 0"
        >
          <span style="color: var(--primary-color); margin: 0">{{
            validateMessage1
          }}</span>
        </div>
        <div class="text-center w-100 mt-3">
          <button v-if="countDown==0" class="ResendCode py-2 px-5 inline-block
                                             tracking-wide align-middle
                                              duration-500 text-base text-center
                                               bg-orange-500 text-white rounded-md w-full" @click="ResendCode()" style="margin: 20px">
            {{ $t("ResendCode") }}
          </button>
          <button @click="checkCode" class="button py-2 px-5 inline-block
                                             tracking-wide align-middle
                                              duration-500 text-base text-center
                                               bg-orange-500 text-white rounded-md w-full" :disabled="countDown==0">
            {{ $t("continue") }}
          </button>
         
         <p v-if="this.$store.state.auth==0" class="alert_msg" style="color:red"> {{ $t('The message could go to junk mail or spam') }} </p>
         <p v-if="this.$store.state.sms_type==3" class="alert_msg" style="color:red"> {{ $t('The message could go to Whatsapp') }} </p>
         <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
        </div>
       
                            </div>
      

                               <div v-if="validateMessage != ''" 
                               style="text-align: center; margin-top: 10px">
                              <span style="color: red; 
                               margin: 0">{{ validateMessage }}*
                               </span>
                               </div>

                              

                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section><!--end section -->

</template>
<script>
import UserInfo from "../components/UserInfo.vue";
import CodeInput from "vue-verification-code-input";

export default {
  components: { CodeInput, UserInfo },
  props: {
    phone: String,
    email: String,
  },
  data: function () {
    return {
      registerUserInfo: false,
      checkcomplete: false,
      validateMessage: "",
      validateMessage1: "",
      finalcode: null,
      countDown: 90,
      loading: false,
    };
  },
  created() {
    this.$store.state.topbar='topbar_dark';
  this.$store.state.navbar='navbar_dark navbar_top';

    this.countDownTimer();
  },
  methods: {
    checkCode() {
      this.loading=true;
    console.log('phone***** ',this.phone)
      if (this.checkcomplete == true) {
        if (this.$store.state.auth == 1) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/register_code",
              {
                /*/register_code*/
                phone: this.phone,
                code: this.finalcode,
                fcm_token: this.$store.state.fsm_token
              },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            this.loading=false;
            if (response.data.code == "-1") {
              
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.registerUserInfo = true;
              if(response.data.data.token)
              this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
               
            }
          });
        }else {
          ///for register by email
          this.loading=true;
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register_code/byemail",
              {
                email: this.email,
                code: this.finalcode,
              },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.loading=false;
              if (response.data.code == "-1") {
                //  console.log( response.data.code);
                this.validateMessage = response.data.message;
                setTimeout(() => {
                  this.validateMessage = "";
                }, 3000);
              } else if (response.data.code == "1") {
                this.registerUserInfo = true;
                //console.log( response.data.code);
                //console.log( response.data.message);
              }
            });
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.validateMessage = "your code isn't complete";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else {
          this.validateMessage = "يرجى إكمال كافة الحقول";
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        }
      }
    },
    ResendCode() {
      if (this.$store.state.auth == 1) {
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/app/genret_code",
          {
            phone: this.phone,
            //country: this.form.country,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          if (response.data.code == "-1") {
            this.validateMessage = response.data.message;
            setTimeout(() => {
              this.validateMessage = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.countDown = 90;
            this.countDownTimer();
            this.validateMessage1 = response.data.message;
            setTimeout(() => {
              this.validateMessage1 = "";
            }, 3000);
          }
        });
      }else {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/genret_code/byemail",
            {
              email: this.email,
              //country: this.form.country,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            if (response.data.code == "-1") {
              this.validateMessage = response.data.message;
              setTimeout(() => {
                this.validateMessage = "";
              }, 3000);
            } else if (response.data.code == "1") {
              this.countDown = 90;
              this.countDownTimer();
              this.validateMessage1 = response.data.message;
              setTimeout(() => {
                this.validateMessage1 = "";
              }, 3000);
            }
          });
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    },
  },
};
</script>
