<template>
     <!-- Start -->
     <section class="relative md:py-24 py-16"  style="padding-bottom: 0rem !important">
    <div
         v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="
        margin-top: 20%;  ;margin-left:45%"
      >
        <!-- LOADER -->
        <div >
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
</div>

            <div v-else class="container relative">
                <div class="grid items-end md:grid-cols-2 mb-6">
                    <div class="md:text-start text-center">
                        <h5 class="font-semibold text-3xl leading-normal mb-4"> {{ $t('Latest products') }}</h5>
                        <p class="text-slate-400 max-w-xl">{{ $t('Shop the latest products from the most popular items') }}</p>
                    </div>

                    <div class="md:text-end hidden md:block">
                        <router-link  :to="{
                              name: 'products',
                              params: { storeName: $store.storeName,lang: $i18n.locale
                              },
                                  }" class="text-slate-400 hover:text-orange-500">
                                  {{ $t('See More Items') }} <i class="mdi mdi-arrow-right"></i></router-link>
                    </div>
                </div><!--end grid-->

                <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
                  <product v-for="(item, index) in this.products"
               :key="index" 
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
        
        >
  
          </product>
                    <!--end content-->

                </div><!--end grid-->

            </div><!--end container-->

        </section><!--end section-->
       
</template>
<script>
import product from "../components/one_product_list.vue";
export default {
  components: {
   product
   },
  data() {
    return {
      products:[],
        n:1,
      DBitems: null,
      loading: false,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
    };
  },
  created() {
    this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/type/new",
      
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
        }
      }
      
    )
    .then((response) => {
      this.products = response.data.data;
     
    })
    .then(() => (this.loading = false));
  },
    watch: {
    langChange() {
      this.loading = true;
    
    this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url + this.$store.storeName + "/theme8/products/type/new",
      
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
        }
      }
      
    )
    .then((response) => {
      this.products = response.data.data;
     
    })
    .then(() => (this.loading = false));
  },
},
updated() {
  this.langChange=this.$i18n.locale
},
  methods: {
  },
};
</script>
