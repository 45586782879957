<template>
    <div>
      <!-- Start Hero -->
      <section class="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
            <div class="container relative">
                <div class="grid grid-cols-1 mt-14">
                    <h3 class="text-3xl leading-normal font-semibold">{{ $t('products') }}</h3>
                </div><!--end grid-->

                <div class="relative mt-3">
                    <ul class="tracking-[0.5px] mb-0 inline-block">
                        <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                            <router-link :to="{
                              name: 'home',
                             params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                     }"
                                     >
                                {{ $t('home') }}
                                </router-link>
                            </li>
                        <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                        <li class="inline-block uppercase text-[13px] font-bold text-orange-500" aria-current="page">{{$t('shop')}}</li>
                    </ul>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Hero -->

        <!-- Start -->
        <section class="relative md:py-24 py-16">
            <div class="container relative">
                <div class="md:flex justify-between items-center mb-6">
                    <span class="font-semibold">{{ $t('Showing') }} {{ pagefrom }}-
                        {{ pageto }}
                        
                         {{ $t('of') }} {{ count }} {{ $t('items') }}</span>

                    <div class="md:flex items-center">
                        <label class="font-semibold md:me-2">{{ $t('Sort by') }}:</label>
                            <select 
                            class="form-select form-input
                             md:w-36 w-full md:mt-0 mt-1
                              py-2 px-3 h-10 bg-transparent
                               dark:bg-slate-900 dark:text-slate-200
                                rounded outline-none border border-gray-100
                                 dark:border-gray-800 focus:ring-0"
                             @change="upadatesort($event)">
                                      <option value="desc/id">{{ $t("default sorting") }}</option>
            <option value="desc/id">{{ $t("sort1") }}</option>
            <option value="asc/id">{{ $t("sort2") }}</option>
            <option value="desc/sale_price">{{ $t("sort3") }}</option>
            <option value="asc/sale_price">{{ $t("sort4") }}</option>
            <option value="desc/count_view">{{ $t("sort5") }}</option>
            <option value="asc/count_view">{{ $t("sort6") }}</option>
            <option value="desc/quantity">{{ $t("sort7") }}</option>
            <option value="asc/quantity">{{ $t("sort8") }}</option>
            <option value="desc/count_sold">{{ $t("sort9") }}</option>
            <option value="asc/count_sold">{{ $t("sort10") }}</option>
          </select>
                    </div>
                </div>
                <div  v-if="loading_product">
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>

                <div v-else class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
                 <product  v-for="(item, index) in this.products"

                 :key="index"
                 :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                 
                 
                 ></product>

                  
                </div><!--end grid-->
               

                <div class="grid md:grid-cols-12 grid-cols-1 mt-6">
                    <div class="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <b-pagination
                v-if="this.products.length!=0"
                v-model="page"
                :total-rows="count"
                :per-page="this.paginate"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="paginate_theme8"
                @change="handlePageChange"
              ></b-pagination>
                        </nav>
                    </div><!--end col-->
                </div><!--end grid-->
                
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
        </div>
</template>


<script>
import product from "../components/one_product_list.vue";

import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { product, HollowDotsSpinner, HalfCircleSpinner},
  data() {
    return {
        DBitems: null,
      loading: false,
      loading_product: false,
      products:[],
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
      pagefrom:1,
      pageto:12
    };
  },
  created() {
    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark';

    this.loading = true;
    this.loading_product = true;
    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName + "/theme5/products",
        
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.products = response.data.data;
        this.count = response.data.meta.total;
        this.pagefrom=response.data.meta.from;
        this.pageto=response.data.meta.to;
        this.loading_product = false
      })
      .then(() => (this.loading = false ));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/products?page=" +
            this.page,
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    closepopup()
    {
      this.$store.state.show_product=false;
    },
    retrieveTutorials() {
      const params = this.page;

      this.loading_product = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/products?page=" +
            params,
          
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },{
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
            }
          
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
          this.pagefrom=response.data.meta.from;
          this.pageto=response.data.meta.to;
        })
        .then(() => (this.loading_product = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },

    upadatesort(event)
  {
    this.order = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      this.filter_product();
  },
  filter_product()
    {
        console.log('brands array  ',this.brands_array);


        this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme5/products",
            {
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate
        },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            }
          }
        )
        .then((response) => {
          this.products = response.data.data;
          //this.count = response.data.meta.total;
        }).then(() => {
        this.loading = false;
      });
    },

  updatepaginate(event)
  {
    this.paginate = event.target.value

      this.filter_product();
  },
  change_style_product($type)
  {
   // this.element=document.getElementById('product_style');
    if ($type=='grid') {
			$('.shop_container').removeClass('list').addClass('grid');
      $('.grid').addClass('active').siblings().removeClass('active');
		//	this.element.addClass('active').siblings().removeClass('active');
		}
		else if($type=='list') {
			$('.shop_container').removeClass('grid').addClass('list');
			//this.element.addClass('active').siblings().removeClass('active');
      $('.list').addClass('active').siblings().removeClass('active');

		}
		$(".shop_container").append('<div class="loading_pr"><div class="mfp-preloader"></div></div>');
		setTimeout(function(){
			$('.loading_pr').remove();
			$container.isotope('layout');
		}, 800);
  }
  },
};
</script>