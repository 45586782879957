<template>
  <section>
    <div class="container-fluid relative mt-6 lg:mx-6 mx-3">
     
            <div v-if="this.sections.length!=0"
               class=" section_grid grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
                
                <div  v-for="(item, index) in this.sections" 
                class="relative overflow-hidden group
                 rounded-md shadow dark:shadow-slate-800">
                    <router-link  :to="{
                       name: 'section',
                      params: { storeName: $store.storeName,
                       id: item.id  ,
                        name:item.name,
                        lang: $i18n.locale},
                              }" 
                        class="text-center">
                        <img :src="item.icon" 
                        class="group-hover:scale-110 duration-500 section_list_image" 
                        :alt="item.name"
                         loading="lazy"
                         style="background-color: lightgrey;"
                        >
                        <span class="section_list_name bg-white dark:bg-slate-900
                         group-hover:text-orange-500 py-2
                          px-6 rounded-full shadow dark:shadow-gray-800
                           absolute bottom-4 mx-4 text-lg font-medium">
                           {{ item.name }}</span>
                    </router-link>
                </div><!--end content-->
            </div><!--end grid-->
        </div><!--end container-->
</section>
</template>
<script>
import { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner } from "epic-spinners";

export default {
  components: { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner},
  data() {
    return {
      section_length: 0,
        DBitems: null,
      loading: false,
     sections:[
               ],
      page: 1,
      count: 0,
      pageSize: 6,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:6,
      type:'new',
     
     
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections/theme8",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data.sections;

      })
      .then(() => (this.loading = false));
      

  },
  watch: {
    langChange() {
      this.sections=[];
      this.langChange=this.$i18n.locale
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections/theme8",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data.sections;
        this

      })
    ;
      
      /////////////////////////////////
      

    },
  },
  updated() {
    this.langChange=this.$i18n.locale
    this.loading = true;
   // this.section_length=0;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections/theme8",
        
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          }
        }
        
      )
      .then((response) => {
        this.sections = response.data.data.sections;
       // this.section_length=1;
       
      })
      setTimeout(() => {
        this.loading = false;
            }, 3000);
  },
  methods: {
  

  },
};
</script>
<style >


</style>