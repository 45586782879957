<template>

  <section class="sections_page" style="margin-top: 10%;">
    <div class="container">
      <h2 style="text-align: center; padding-bottom: 2%;" class="page_title">{{ $t("sections") }}</h2>
      <div v-if="loading" class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />
      </div>
      <div v-else-if="!this.DBitems || this.DBitems.length == 0">
        <div class="empty text-center center-text">

          <img src="/pet_shop/assets/images/empty.png" />
          <br>
          {{ $t('empty') }}
        </div>
      </div>
      <div v-else class="row">
        <div v-for="(item, index) in this.DBitems" :key="index" class="col-lg-3 col-md-3 col-6">
          <router-link class="section_list_link" :to="{
            name: 'section',
            params: {
              storeName: $store.storeName,
              lang: $i18n.locale,
              id: item.id,
              name: item.name
            },
          }">

            <div class="center-text text-center">
              <img :src=item.icon class=" imag_section one_section_image">
              <h5 class="section_list_name">{{ item.name }}</h5>
            </div>
          </router-link>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center mt-5">
        <b-pagination v-model="page" :total-rows="count" :per-page="pageSize" :prev-text="$t('prev')"
          :next-text="$t('next')" pills @change="handlePageChange"></b-pagination>
      </div>
    </div>
  </section>


</template>
<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";
import { RouterLink } from "vue-router";

export default {
  components: { HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      DBitems: null,
      loading: false,
      page: 1,
      count: 0,
      pageSize: 12,
      langChange: ""
    };
  },
  created() {


    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; 
      this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }

    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data.sections;
        this.pageSize = response.data.meta.per_page;

        this.count = response.data.meta.total;
      })
      .then(() => {
        this.loading = false;
      });
    if (this.$i18n.locale == 'ar') {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content = this.$store.state.description_ar;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
    }
    else {
      document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content = this.$store.state.description_en;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
    }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/sections?page=" +
          this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        }).then(() => {
          this.loading = false;
        });
    },
  },
  updated() {
    this.langChange = this.$i18n.locale

  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/sections?page=" +
          params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.sections;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false));

    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
  },
};
</script>