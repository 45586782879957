<template>
    <div style="min-height:600px">
      <div
         v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 20%;  ;margin-left:45%"
      >
        <!-- LOADER -->
        <div >
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
      </div>
<!-- Start Hero -->
<section v-if="loading==false" class="relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
            <div class="container relative">
                <div class="grid grid-cols-1 mt-14">
                    <h3 class="text-3xl leading-normal font-semibold">{{ this.name }}</h3>
                </div><!--end grid-->

                <div class="relative mt-3">
                    <ul class="tracking-[0.5px] mb-0 inline-block">
                        <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                            <router-link 
                            to="/home">
                            {{$t('home')}}</router-link>
                            </li>
                        <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                        <li class="inline-block uppercase text-[13px] font-bold text-orange-500" 
                        aria-current="page">{{ $t('products') }}</li>
                    </ul>
                </div>
            </div><!--end container-->
        </section><!--end section-->
        <!-- End Hero -->

        <!-- Start -->
        <section  v-if="loading==false" class="relative md:py-24 py-16">
            <div class="container relative">
                <div class="grid md:grid-cols-12 sm:grid-cols-2 grid-cols-1 gap-6">
                    <div class="lg:col-span-3 md:col-span-4">
                        <div class="rounded shadow dark:shadow-gray-800 p-4 sticky top-20">
                            <h5 class="text-xl font-medium">{{ $t('filter') }}</h5>

                            <div v-if="this.categories.length!=0" class="mt-4">
                                <h5 class="font-medium">{{ this.name }}:</h5>
                                <ul class="list-none mt-2">
                                    <li id="li_all_category" class="category active">
                                    <a 
                                   @click="filter_category(0,'all_category')" id='all_category'
                                     class=" text-slate-400 dark:text-gray-100">
                                        <i class="mdi mdi-shopping-outline primary_color me-2"></i>
                                        
                                    {{ $t('all') }} ({{total }})
                                       </a></li>
                                    <li  v-for="(item, index) in this.categories"
                                    :key="index"
                                     :id="'li_category'+index"
                                    class="category">
                                    <a 
                                    @click="filter_category(item.id,index)"
                                     :id="'category'+index"
                                     class="category text-slate-400 dark:text-gray-100">
                                        <i class="mdi mdi-shopping-outline primary_color me-2"></i>
                                        
                                    {{ item.label }} ({{ item.count_product }})
                                       </a></li>
                                    
                                </ul>
                            </div>

                            <div v-if="this.brands.length!=0" class="mt-4">
                                <h5 class="font-medium">{{ $t('brand') }}:</h5>
                                <ul class="list-none mt-2">
                                    <li  v-for="(item, index) in this.brands"
                                    :key="index">
                                    <a 
                                    :for=item.id
                                     class="text-slate-400 dark:text-gray-100">
                                        <i class="mdi mdi-shopping-outline text-orange-500 me-2 primary_color"></i>
                                        <input class="form-check-input"
                                     type="checkbox"
                                    :id=item.id 
                                    :value=item.id
                                    v-model="brands_array"
                                    @change="filter_product()"
                                    checked
                                    >
                                    {{ item.label }}
                                       </a></li>
                                    
                                </ul>
                            </div>

                            <div v-for="(item, index) in this.options"
                            :key="index"
                            class="mt-4">
                                <h5 class="font-medium">{{ item.name}}:</h5>
                                <ul class="list-none mt-2">
                                    <li 
                                    v-for="(item2, index) in item.option_values.select_values"
                                 :key="index"
                                    class="inline" style="
    width: fit-content !important;
    margin:2px;
    padding:2px 5px;
">
                                        <label 
                                        :id="'value'+item2.id"
                                        class="size-7 inline-flex items-center justify-center
                                         tracking-wide align-middle text-base text-center
                                          rounded-md border border-gray-100 dark:border-gray-800 
                                          text-slate-900 dark:text-gray-50 hover:border-slate-900 
                                          dark:hover:border-gray-100 hover:text-white dark:hover:
                                          text-slate-900 hover:bg-slate-900 dark:hover:bg-slate-100"
                                          :for="item2.id"
                                          style="
                                  width: fit-content !important;
                                  margin:2px;
                                  padding:2px 5px;
                                  min-width:50px;
                                                   "
                                          >
                                          
                                          <input class="form-check-input"
                                     type="checkbox" 
                                     name="checkbox" 
                                     :id=item2.id 
                                     :value=item2.id
                                     v-model=options_array
                                     @change="check_option(item2.id)"
                                     style="display:none;
                                      width: fit-content !important;
                                     margin:2px;
                                  padding:2px 5px;
                                  min-width:50px;"
                                    
                                     >
                                          {{ item2.name }}</label></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div><!--end col-->

                    <div class="lg:col-span-9 md:col-span-8">
                        <div class="md:flex justify-between items-center mb-6">
                            <span class="font-semibold">{{ $t('Showing') }} {{ pagefrom }}-
                        {{ pageto }}
                        
                         {{ $t('of') }} {{ count }} {{ $t('items') }}</span>
        
                            <div class="md:flex items-center">
                                <label class="font-semibold md:me-2">{{ $t('Sort by') }}:</label>
                                <select 
                                 @change="upadatesort($event)"
                                class="form-select form-input md:w-36 w-full md:mt-0 mt-1 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0">
                                      <option value="desc/id">{{ $t("default sorting") }}</option>
            <option value="desc/id">{{ $t("sort1") }}</option>
            <option value="asc/id">{{ $t("sort2") }}</option>
            <option value="desc/sale_price">{{ $t("sort3") }}</option>
            <option value="asc/sale_price">{{ $t("sort4") }}</option>
            <option value="desc/count_view">{{ $t("sort5") }}</option>
            <option value="asc/count_view">{{ $t("sort6") }}</option>
            <option value="desc/quantity">{{ $t("sort7") }}</option>
            <option value="asc/quantity">{{ $t("sort8") }}</option>
            <option value="desc/count_sold">{{ $t("sort9") }}</option>
            <option value="asc/count_sold">{{ $t("sort10") }}</option>
          </select>
                            </div>
                        </div>
                        <div v-if="this.products.length!=0" class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                           <product
                           v-for="(item, index) in this.products"
                      :key="index"
                       
                       :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
                           >
                            </product>
                        </div><!--end grid-->
                        
                        <div v-else-if="this.loading">
                  <div class="empty text-center center-text ">
                 
                  <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                  <br>
                  
                  </div>
                  </div>
                        <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>
                        <div class="grid md:grid-cols-12 grid-cols-1 mt-6">
                    <div class="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <b-pagination
                v-if="this.products.length!=0"
                v-model="page"
                :total-rows="count"
                :per-page="this.paginate"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="paginate_theme8"
                @change="handlePageChange"
              ></b-pagination>
                        </nav>
                    </div><!--end col-->
                </div><!--end grid-->
                    </div><!--end col-->
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
   </div>
</template>

<script>
import product from "../components/one_product_list.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { product, HollowDotsSpinner, HalfCircleSpinner},
  data() {
    return {
      DBitems: null,
      loading: false,
      element:null,
      page: 1,
      count: 0,
      pageSize: 12,
      langChange:"",
      products:null,
      name:null,
      categories:null,
      brands:null,
      options:null,
      options_array:[],
      brands_array:[],
      category_id:null,
      sort:'id',
      order:'desc',
      paginate:12,
       section_id:null,
       price_from:0,
       price_to:null,
       pagefrom:1,
       pageto:12,
       total:0
    };
  },
  created() {
    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark';

    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
         "/theme5/section/"+this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
      
        this.DBitems = response.data.data;

        console.log('test ',response.data.data);

        this.name=response.data.data.name;
        this.brands=this.DBitems.brands;
        this.categories=this.DBitems.categories;
        this.options=this.DBitems.options;


      })
      .then(() => {
        this.loading = false;
      });



      //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
        this.total = response.data.meta.total;
        this.pagefrom=response.data.meta.from;
        this.pageto=response.data.meta.to;

      })
      ;

      /////////////////////////
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    
  },
  watch: {
    langChange() {
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
         "/theme5/section/"+this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
      
        this.DBitems = response.data.data;

        console.log('test ',response.data.data);

        this.name=response.data.data.name;
        this.brands=this.DBitems.brands;
        this.categories=this.DBitems.categories;
        this.options=this.DBitems.options;


      })
      .then(() => {
        this.loading = false;
      });



      //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
        this.pagefrom=response.data.meta.from;
        this.pageto=response.data.meta.to;
      })
      ;

    },
    "$route.params": {
      handler(newValue) {
        this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
         "/theme5/section/"+this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
      
        this.DBitems = response.data.data;

        console.log('test ',response.data.data);

        this.name=response.data.data.name;
        this.brands=this.DBitems.brands;
        this.categories=this.DBitems.categories;
        this.options=this.DBitems.options;


      })
      .then(() => {
        this.loading = false;
      });



      //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
        this.pagefrom=response.data.meta.from;
        this.pageto=response.data.meta.to;
      })
      ;

      },
      immediate: true
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
  
  },
  methods: {
    check_option(id)
    {
      this.filter_product();
      console.log('444444444')
 var check_box=document.getElementById(id);
 if(check_box.checked==true)
 {document.getElementById('value'+id).style=
 "background-color:var(--primary-color) !important;display:none;width: fit-content !important;margin:2px;padding:2px 5px; min-width:50px;color:white !important"}
 else
 {document.getElementById('value'+id).style="background-color:white;width:fit-content !important;margin:2px;padding:2px 5px; min-width:50px;"}
 
    },
    
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;

      this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5?page="+this.page,
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
         this.count = response.data.meta.total;
         this.loading = false;

      })
      
     
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },


    filter_product()
    {
        console.log('brands array  ',this.brands_array);


        this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
         "/products/filter_product_theme_5",
        {
            section_id: this.$route.params.id,
            category: this.category_id,
            options: this.options_array,
            sort:  this.sort,
            order: this.order,
            paginate: this.paginate,
            brands:  this.brands_array
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale
           
          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
      })
    }

    ,
    filter_category($id,$index)
    {
        this.element=document.getElementById('li_category'+$index);
        document.getElementById('li_all_category').classList.remove("active");

        for(var i=0;i<this.categories.length;i++)
        {
          var element2=document.getElementById('li_category'+i);
          if(element2)
          element2.classList.remove("active");

        }
        if(this.element)
        this.element.classList.add("active");
       if($id==0)
      {
        document.getElementById('li_all_category').classList.add("active");

      }
        this.category_id=$id;
       this.filter_product();
    }
    ,
    upadatesort(event)
  {
    this.order = event.target.value.split("/")[0]; //asc
      this.sort = event.target.value.split("/")[1]; //id

      this.filter_product();
  },

  updatepaginate(event)
  {
    this.paginate = event.target.value

      this.filter_product();
  },

  ////////////
  /*===================================*
	18. List Grid JS
	*===================================*/
  },

 
};
</script>

