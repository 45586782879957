<template>
    <!-- Start Hero -->
    <section style="position:absloute"
    v-if="this.$localStorage.get(this.$store.storeName) != null"
    class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
               <div class="md:container container-fluid relative">
                   <div class="relative overflow-hidden md:rounded-md shadow
                    dark:shadow-gray-700 h-52 
                    bg-[url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/217/section/2024/03/327518311709552687.jpg')] 
                    bg-center bg-no-repeat bg-cover"  
                    style="background-image:url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/227/slider/2024/08/1723628771_1723717856.jpg')"
                    ></div>
               </div><!--end container-->
   
               <div class="container relative md:mt-24 mt-16">
                   <div class="md:flex">
                       <side_bar></side_bar>
   
                       <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                        <div class="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <div class="p-6 border-b border-gray-100 dark:border-gray-700">
                                <h5 class="text-lg font-semibold">{{ $t('Account Notifications') }}</h5>
                            </div>

                            <div 
                            v-for="(item, index) in this.notifications"
                           :key="index"
                            class="p-6">
                                <div class="flex justify-between pb-4">
                                    <h6 class="mb-0 font-medium">{{ item.title }}</h6>
                                    <div class="">
                                        <div>
                                        <a  @click="deleteNote(item.id,index)" 
                                        class="size-9 inline-flex items-center
                                         justify-center tracking-wide align-middle
                                          duration-500 text-base text-center
                                           bg-red-600/5 hover:bg-red-600
                                            text-red-600 hover:text-white
                                             rounded-full">
                                             <i v-html="trash_2" class="h-5 w-5"></i></a>
                                    </div>
                                    </div>
                                </div>
                                <div class="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                    <h6 class="mb-0 font-medium">{{ item.body }}</h6>
                                    <div class="">
                                    {{ item.date }}
                                    </div>
                                </div>
                             
                            </div>
                        </div>

                     
                    </div>
                   </div><!--end grid-->
               </div><!--end container-->

               <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdeleteAllNoti')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAll() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show1" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmdeleteOneNoti')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteOneNote() ">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show1 = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
           </section><!--end section-->
           <!-- End Hero -->
   </template>

   <script>
import side_bar from "../components/sidebar_profile.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { side_bar, HollowDotsSpinner, HalfCircleSpinner },
  data() {
    return {
      loading: false,
      show: false,
      show1: false,
      deleteNoteId: null,
      deleteNoteIndex: null,
      page: 1,
      count: 0,
      pageSize: 5,
      langChange: "",
      notifications: null
    };
  },
  created() {
    this.$store.state.topbar='topbar_dark';
    this.$store.state.navbar='navbar_dark navbar_top';
    
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/new_note",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        if (response.data.data.code == "1") {
          this.notifications = response.data.data.notes;
          this.count = response.data.meta.total;
          console.log(this.notifications);
        }
      })
      .then((this.loading = false));

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/new_note?page=" +
            this.page,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            // console.log(this.notifications);
            this.pageSize=response.data.meta.per_page;

          }
        })
        .then(() => {
          this.loading = false;
        });
    }
  },
  updated() {
    this.langChange = this.$i18n.locale;
  },
  computed: {
    trash_2: function () {
      return feather.toSvg('trash-2')
    },
    
  },
  methods: {
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/new_note?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            //console.log(this.notifications);
          }
        })
        .then(() => (this.loading = false));
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    deleteAll() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_allnote",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.notifications = null;
            this.count = 0;
          }
        })
        .then(() => {
          this.loading = false;
          this.show = false;
        });
    },
    deleteOneNote() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_note/" +
            this.deleteNoteId,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$delete(this.notifications, this.deleteNoteIndex);
            this.$store.state.notificationUpdate= !this.$store.state.notificationUpdate;
            // this.$el.parentNode.removeChild(this.$el);
          }
        })
        .then(() => {
          this.loading = false;
          this.show1 = false;
        });
    },
    deleteNote(itemId, itemIndex) {
      this.show1 = true;
      this.deleteNoteId = itemId;
      this.deleteNoteIndex = itemIndex;
    }
  }
};
</script>
