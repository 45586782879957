<template>
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <section id="Hero" v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-12 order-lg-1">
            <div>
              <lingallery
                :addons="{ enableLargeView: true }"
                :iid.sync="currentId"
                :width="width"
                :height="height"
                :items="this.images"
              />
            </div>
          </div>
          <div class="col-lg-7 col-12 order-lg-2">
            <div class="d-flex flex-row justify-content-between pb-0">
              <div class="section-title pb-0">
                <h2>{{ DBitems.product.name }}</h2>
              </div>
              <div class="d-flex flex-row justify-content-between pb-0">
                <router-link
                  :to="{
                    name: 'login',
                    params: { storeName: this.$store.storeName,lang: $i18n.locale },
                  }"
                  v-if="loginMassage != ''"
                  style="
                    margin: 10px;
                    color: var(--primary-color);
                    text-decoration: 1px underline !important;
                    text-underline-offset: 5px;
                  "
                >{{ loginMassage }}</router-link>

                <!--v-if="this.$localStorage.get(this.$store.storeName) != null"-->
                <img
                  class="fav-img"
                  :style="fav ? 'display: none' : 'display: block'"
                  src="../../../public/assets/image/icons/fav.svg"
                  @click="changeFav"
                />
                <img
                  class="fav-full-img"
                  :style="fav ? 'display: block' : 'display: none'"
                  src="../../../public/assets/image/icons/fav-fill.svg"
                  @click="changeFav"
                />
                <!-- <vodal
                      :show="loginVodal"
                      animation="flip"
                      @hide="loginVodal = false"
                      :width="600"
                      :height="450"
                      style="margin-top: 20px"
                    >
                      <login></login>
                </vodal>-->
              </div>
            </div>
            <hr class="mobile-inactive" />
            <div class="row">
              <div class="col-sm-7">
                <div class="star d-flex flex-row justify-content-center w-75 mt-0">
                  <div class="d-flex flex-row justify-content-center">
                    <span v-for="n in DBitems.rate" :key="n">
                      <i class="star-fill bi bi-star-fill"></i>
                    </span>
                    <div class="d-flex flex-row justify-content-center">
                      <span v-for="n in 5 - DBitems.rate" :key="n">
                        <i class="star bi bi-star-fill"></i>
                      </span>
                      <span style="margin-top: 15px">({{ DBitems.count_rate }})</span>
                    </div>
                  </div>

                  <!--<b-form-rating
                    v-model="DBitems.rate"
                    readonly
                    show-value
                    show-value-max
                    precision="2"
                  ></b-form-rating>-->
                  <div>
                    <button
                      style="
                        background-color: #efefef;
                        border-radius: 5px;
                        text-align: center;
                        width: 35px;
                        height: 35px;
                        border: 0;
                        margin: 0 10px 0 10px;
                      "
                      @click="addEvalu"
                    >
                      <h5 href class="hovertext" data-hover="إضافة تقييم">+</h5>
                    </button>
                    <vodal
                      :show="addeval"
                      animation="flip"
                      @hide="addeval = false"
                      :width="600"
                      :height="450"
                      style="margin-top: 20px"
                                          >
                  <add-evaluation></add-evaluation>
                    </vodal>
                  </div>
                </div>
              </div>
              <div class="col-sm-5 text-center mobile-inactive">
                <div class="myBtn">{{ DBitems.count_sold }} {{ $t("bought") }}</div>
              </div>
            </div>
            <hr />
            <div
              v-if="DBitems.product.brand_logo != null"
              class="d-flex flex-row justify-content-start align-items-center"
              style="margin-bottom: 30px"
            >
              <div class="barndImg">
                <img :src="DBitems.product.brand_logo" />
              </div>
              <h5>{{ $t("brand") }}</h5>
              <h5>: {{ DBitems.product.brand_name }}</h5>
            </div>
            <div
              class="information d-flex flex-row justify-content-between w-50"
              v-for="(info, index) in this.DBitems.product_informations
                .product_informations"
              :key="index"
            >
              <h5>{{ info.option }} :</h5>
              <h5>{{ info.value }}</h5>
            </div>
            <div class="container" v-html="DBitems.product.description"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 col-12">
            <div
              class="orderInfo d-flex flex-row justify-content-between mt-4"
              v-if="this.DBitems.product.is_offer == true"
            >
              <span>{{ $t("offerProduct") }} :</span>
              <h5 style="color: var(--primary-color)">{{ this.DBitems.product.discount }} %</h5>
            </div>
            <div
              class="orderInfo d-flex flex-row justify-content-between mt-1"
              v-if="this.DBitems.product.is_offer == true"
            >
              <span>{{ $t("offerEnd") }} :</span>
              <h5 style="color: var(--primary-color)">{{ this.DBitems.product.offer_end }}</h5>
            </div>
            <div
              class="orderInfo d-flex flex-row justify-content-between mt-1"
              v-if="this.DBitems.tax != 0"
            >
              <span>{{ $t("thereistax") }} :</span>
              <h5 style="color: var(--primary-color)">{{ this.DBitems.tax }} %</h5>
            </div>
            <hr class="mobile-inactive" />
            <!--------------------------------------------------------------------->
            <div
              class="orderInfo d-flex flex-row justify-content-between mt-4"
              style="margin-bottom: 15px"
            >
              <h5>{{ $t("price") }} :</h5>
              <div v-if="selectOption">{{ $t("selectOption") }}</div>
              <div v-else class="d-flex flex-row justify-content-between">
                <h5
                  v-if="$i18n.locale == 'ar'"
                  :style="
                  offer_price>0
                      ? '  text-decoration: line-through ; font-size: 12px ;'
                      : 'color: var(--primary-color)'
                  "
                >{{ price.toFixed(2) }} {{ $store.state.unitAR }}</h5>
                <h5
                  v-if="$i18n.locale == 'en'"
                  :style="
                    this.DBitems.product.is_offer
                      ? '  text-decoration: line-through ; font-size: 12px'
                      : 'color: var(--primary-color)'
                  "
                >{{ price.toFixed(2) }} {{ $store.state.unit }}</h5>
                <h5
                  v-if="
                  offer_price>0 &&
                    $i18n.locale == 'ar'
                  "
                  style="color: var(--primary-color)"
                >{{ offer_price.toFixed(2) }} {{ $store.state.unitAR }}</h5>
                <h5
                  v-if="
                  offer_price>0
                     &&
                    $i18n.locale == 'en'
                  "
                >{{ offer_price.toFixed(2) }} {{ $store.state.unit }}</h5>
              </div>
            </div>
            <div
              class="orderInfo d-flex flex-row justify-content-between"
              style="margin-bottom: 15px"
            >
              <h5>{{ $t("quantity") }} :</h5>

              <div v-if="(quanity == 0||max_quanity == 0) && !selectOption">{{ $t("NoQuantity") }}</div>
              <div v-else-if="selectOption">{{ $t("selectOption") }}</div>
              <div
                v-else
                class="d-flex flex-row justify-content-start align-items-center text-center"
                style="
                  background-color: #fff;
                  box-shadow: 0px 0px 8px rgba(127, 137, 161, 0.25);
                  border-radius: 10px;
                "
              >
                <button
                  style="
                    background-color: transparent;
                    border: 0;
                    height: 20px;
                    min-width: 40px;
                  "
                  @click="incrementClick()"
                >
                  <h4 style="font-size: 20px; line-height: 20px">+</h4>
                </button>
                <div>
                  <h4
                    style="
                      background-color: transparent;
                      margin: 10px;
                      height: 20px;
                      min-width: 30px;
                      line-height: 20px;
                    "
                    id="counter-label"
                  >{{ counterVal }}</h4>
                </div>
                <button
                  style="
                    background-color: transparent;
                    border: 0;
                    height: 20px;
                    min-width: 40px;
                  "
                  @click="decrementClick()"
                >
                  <h4 style="font-size: 20px; line-height: 20px">-</h4>
                </button>
              </div>
            </div>
            <div v-for="(item, index) in DBitems.options.options" :key="index">
              <div
                v-if="item.type == 'select'"
                class="orderInfo d-flex flex-row justify-content-between"
                style="margin-bottom: 15px"
              >
                <h5>{{ item.name }} :</h5>
                <select
                  class="select"
                  :title="$t('SelectOne')"
                  @change="upadateprice($event, item.id)"
                >
                  <option hidden selected>{{ $t("SelectOne") }}</option>
                  <option
                    :value="item1.id"
                    v-for="(item1, index) in item.option_values.select_values"
                    :key="index"
                  >{{ item1.name }}</option>
                </select>
              </div>
              <div v-if="item.type == 'checkbox'">
                <div v-for="(item1, index1) in checked" :key="index1">
                  <div
                    class="orderInfo d-flex flex-row justify-content-between"
                    style="margin-bottom: 15px"
                    v-if="checked[index1].id == item.id"
                  >
                    <h5>{{ item.name }} :</h5>
                    <div class="d-flex flex-row justify-content-between" style="width: 120px">
                      <label
                        v-if="$i18n.locale == 'ar'"
                        for="checkbox"
                      >{{ item.price }} {{ $store.state.unitAR }}</label>
                      <label v-else for="checkbox">{{ item.price }} {{ $store.state.unit }}</label>
                      <input
                        type="checkbox"
                        id="checkbox"
                        v-model="checked[index1].value"
                        style="width: 30px; height: 25px"
                        @change="changeCheckbox(item, checked[index1].value)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="quanity != 0||max_quanity == 0" class="orderInfo mt-4">
              <h5>{{ $t("note") }}</h5>
              <textarea rows="5" cols="5" class="form-control" required placeholder v-model="note"></textarea>
            </div>
            <button
              id="#addToCart"
              v-if="quanity != 0||max_quanity == 0"
              class="orderInfo button mt-4"
              @click="addToCart"
            >{{ $t("Addtocart") }}</button>
          </div>
          <div class="col-lg-7 col-12"></div>
        </div>
      </div>
    </section>
    <div
      v-if="validateMessage != ''"
      style="
        position: fixed;
        z-index: 100;
        width: 300px;
        bottom: 50px;
        left: 50px;
        border-radius: 5px;
        background-color: #fff;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding-top: 5px;
      "
    >
      <p>{{ validateMessage }}</p>
    </div>
    <!-- ======= Second Section ======= -->
    <section id="Second" v-if="!loading">
      <div class="container" v-if="this.DBitems.similar_products.products.length != 0">
        <div class="section-title">
          <h2>{{ $t("similarProducts") }}</h2>
        </div>
        <div class="row">
          <div
            class="collg6 col-lg-3 col-md-4 col-sm-6 col-6 mb-5"
            v-for="(item, index) in this.DBitems.similar_products.products"
            :key="index"
          >
            <card3
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
            ></card3>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- End Second -->
</template>

<script>
import card3 from "../components/CardStyle3.vue";
import AddEvaluation from "../components/AddEvaluation.vue";
import Login from "./Login.vue";

import Lingallery from "lingallery";
import img from "../../../public/assets/image/slider/portfolio-8.jpg";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

export default {
  components: { Vodal, card3, Lingallery, AddEvaluation, Login },

  data: function() {
    return {
      counterVal: 1,
      loginVodal: false,
      loginMassage: "",
       old_qnt:0,
      currentId: null,
      width: 600,
      height: 350,

      DBitems: null,
      loading: false,

      options: [],
      selectedOptionArray: [],
      price: 0,
      quanity: 0,
      max_quanity: 0,
      min_quanity: 0,
      Firstquanity: 0,
      finalOptions: 0,
      checked: [],
      validateMessage: "",
      selectOption: false,
      images:null,
      count_images:null,
      fav: false,
      offer_price: 0,
      //is_offer: false,
      //discount: null,

      note: "",

      addeval: false
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/product/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.checked = [];
        this.DBitems = response.data.data;
        if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
        this.fav = this.DBitems.product.is_favorit;
        this.images=response.data.data.images.images;
       // this.old_images=response.data.data.images.images;
        this.count_images=response.data.data.images.images.length
        var option1 = this.DBitems.options.options;
        for (var i = 0; i < option1.length; i++) {
          //if option  not empty
          if (option1[i].option_values.select_values != null) {
            // if select
            var element = {};
            element.value_id = option1[i].option_values.select_values[0].id;
            element.option_id = option1[i].id;
            this.options.push(element);
            ///console.log(this.options.length +"iam theeeeeeeeeeeeeeer")
          } else {
            // if checkbox
            var checked = {};
            checked.id = option1[i].id;
            checked.value = false;
            this.checked.push(checked);
            //console.log(this.checked)
          }
        }
        //console.log(this.options)
      })
      .then(() => {
        this.loading = false;
        if (this.options.length != 0) {
          this.selectOption = true;
          /*  // if we found select options
          var options = this.options;
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/product_price/" +
                this.$route.params.id,
              {
                options,
              },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.price = response.data.data.price;
              this.offer_price = response.data.data.new_price;
              this.quanity = response.data.data.quanity;
              this.Firstquanity = response.data.data.quanity;
              this.finalOptions = response.data.data.final_option;

              /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
              /////////////counteer////////////

              var checked_true = [];
              for (var i = 0; i < this.checked.length; i++) {
                if (this.checked[i].value == true) {
                  checked_true.push(this.checked[i].id);
                }
              }
              let S_name_full =
                this.$store.storeName +
                "/" +
                this.$route.params.id +
                this.finalOptions +
                checked_true;

              let S_name2 =
                this.$store.storeName +
                "/" +
                this.$route.params.id +
                this.finalOptions;
              console.log("S_name " + S_name2);

              let qun = this.quanity;

              let AllCookies = this.$cookies.keys();
              for (let i = 0; i < AllCookies.length; i++) {
                if (AllCookies[i].includes(S_name2)) {
                  // if (AllCookies[i] == S_name_full) {
                  //  console.log("hii i am there ");
                  //} else {
                  qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                  console.log(
                    this.$cookies.get(AllCookies[i]).quantity +
                      " quuuuun " +
                      qun
                  );
                  // }
                }
              }
              this.quanity = qun;
            });*/
        } 
        else if (this.options.length == 0) {
          // if we not found select options
          //console.log("hiiiiiiiiii created" + this.DBitems.product.quanity);
          this.price = this.DBitems.product.sale_price;
          this.offer_price = this.DBitems.product.offer_price;
          this.quanity = this.DBitems.product.quantity;
          this.max_quanity = this.DBitems.product.quantity;
          this.min_quanity=1;
          if(this.DBitems.product.min_qnt)
          this.min_quanity = this.DBitems.product.min_qnt;
          if(this.DBitems.product.max_qnt>0)
          this.max_quanity = this.DBitems.product.max_qnt;
          this.Firstquanity = this.DBitems.product.quantity;

          /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
          var checked_true = [];
          for (var i = 0; i < this.checked.length; i++) {
            if (this.checked[i].value == true) {
              checked_true.push(this.checked[i].id);
            }
          }
          let S_name_full =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions +
            checked_true;

          let S_name2 =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions;
          console.log("S_name " + S_name2);

          let qun = this.quanity;
          let min_qun = this.min_quanity;
          let max_qun = this.max_quanity;
          let AllCookies = this.$cookies.keys();
          for (let i = 0; i < AllCookies.length; i++) {
            if (AllCookies[i].includes(S_name2)) {
              // if (AllCookies[i] == S_name_full) {
              //  console.log("hii i am there ");
              //} else {
                this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
              qun = qun - this.$cookies.get(AllCookies[i]).quantity;
             min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
             if(min_qun<0)
             {
              min_qun=1;
             }
             if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 
              console.log(
                this.$cookies.get(AllCookies[i]).quantity + " quuuuunb" + qun
              );
              // }
            }
          }
          this.quanity = qun;
          this.min_quanity = min_qun;
          this.max_quanity = max_qun;
        
        }
        if(this.min_quanity>0)
         {
          this.counterVal=this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
         }
      });


      //counter value 

     
         
      //end counter value
  },
  methods: {
    incrementClick() {
    

      if (this.quanity > 0) {
        console.log("*************** max qnt   "+this.max_quanity+" ");
        if (this.counterVal < this.quanity&&this.counterVal < this.max_quanity) {
          ++this.counterVal;
          this.price = this.price / (this.counterVal - 1) + this.price;
          if (this.offer_price>0) {
            this.offer_price =
              this.offer_price / (this.counterVal - 1) + this.offer_price;
            /*   this.offer_price =
              this.price - (this.DBitems.product.discount / 100) * this.price;*/
          }
        }
      } else {
        ++this.counterVal;
        this.price = this.price / (this.counterVal - 1) + this.price;
        if (this.offer_price>0) {
          this.offer_price =
            this.offer_price / (this.counterVal - 1) + this.offer_price;
          /* this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
      //}
    },
    decrementClick() {
      if (this.counterVal > 1&&this.counterVal >this.min_quanity) {
        --this.counterVal;
        //this.price = this.price/2
        this.price = this.price - this.price / (this.counterVal + 1);
        if (this.offer_price>0) {
          this.offer_price =
            this.offer_price - this.offer_price / (this.counterVal + 1);
          /*    this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
    },
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/product/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
          if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
      this.images=response.data.data.images.images;
          // this.counterVal = 1;
          //   if(this.DBitems.min_quanity>1)
          //   this.counterVal = this.DBitems.min_quanity;
          this.fav = this.DBitems.product.is_favorit;
        })
        .then(() => (this.loading = false));

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },
    upadateprice(event, option_id) {
      for (var i = 0; i < this.options.length; i++) {
        if (this.options[i].option_id == option_id) {
          this.options[i].value_id = event.target.value;
          if (this.selectedOptionArray.length < this.options.length) {
            //if (!this.selectedOptionArray.includes(this.options[i].option_id)) {
            this.selectedOptionArray.push(this.options[i].option_id);
            //}
          }
        }
      }
      //console.log(this.options.length  +" hii " +this.selectedOptionArray.length)
      if (this.options.length == this.selectedOptionArray.length) {
        this.selectOption = false;
        // console.log(this.options)
        //option1.get(event)
        /*var options= [
        {
          option_id: 1,
          value_id: 2,
        },
        {
          option_id: 1,
          value_id: 2,
        },
      ];*/
        var options = this.options;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/product_price/" +
              this.$route.params.id,
            {
              options
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            this.price = response.data.data.price;
            this.offer_price = response.data.data.new_price;
            this.quanity = response.data.data.quanity;
            this.max_quanity = response.data.data.quanity;
            this.min_quanity=1;
            if(response.data.data.min_qnt>1)
            this.min_quanity = response.data.data.min_qnt;
            if(response.data.data.max_qnt>0)
            this.max_quanity = response.data.data.max_qnt;
            this.Firstquanity = response.data.data.quanity;
            this.finalOptions = response.data.data.final_option;
            for (var i = 0; i < this.checked.length; i++) {
              this.checked[i].value = false;
            }
            if(response.data.data.image)
            {
           //   this.images=[];
           // this.images=this.old_images;
                 if(this.images.length>this.count_images)
                 this.images.shift();
            //console.log('count old image',this.old_images.length,"  count images  ",this.images.length)
              this.images.unshift(
               
                {
                  id:'1',
                 src: response.data.data.image,
                  thumbnail: response.data.data.image
                }
               
              )
            }
            /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
            // this.counterVal = 1;
            // if(this.min_quanity>1)
            // this.counterVal = this.min_quanity;
            this.note = "";
            var checked_true = [];
            for (var i = 0; i < this.checked.length; i++) {
              if (this.checked[i].value == true) {
                checked_true.push(this.checked[i].id);
              }
            }
            let S_name_full =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;

            let S_name2 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions;
            console.log("S_name " + S_name2);

            let qun = this.quanity;
            let min_qun = this.min_quanity;
            let max_qun = this.max_quanity;

            let AllCookies = this.$cookies.keys();
            for (let i = 0; i < AllCookies.length; i++) {
              if (AllCookies[i].includes(S_name2)) {
                // if (AllCookies[i] == S_name_full) {
                //  console.log("hii i am there ");
                //} else {
                  this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
                qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
             if(min_qun<0)
             {
              min_qun=1;
             }
             if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 

                console.log(
                  this.$cookies.get(AllCookies[i]).quantity + " quuuuunx " + qun
                );
                // }
              }
            }
            this.quanity = qun;
            this.max_quanity = max_qun;
            this.min_quanity = min_qun;
            

//test
if(this.min_quanity>0)
         {
          this.counterVal=this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
         }
         else
              {
          this.counterVal=1;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
              } 


          });
      }
    },
    updateall() {
      this.loading = true;
      this.selectOption = false;
      this.counterVal = 1;
      this.options = [];
      this.selectedOptionArray = [];
      this.price = 0;
      this.offer_price = 0;
      this.quanity = 0;
      this.min_quanity = 0;
      this.max_quanity = 0;
      this.Firstquanity = 0;
      this.finalOptions = 0;
      this.checked = [];
      //this.validateMessage = "";

      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/product/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.checked = [];
          this.DBitems = response.data.data;
          if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
            this.images=response.data.data.images.images;

          this.fav = this.DBitems.product.is_favorit;
          var option1 = this.DBitems.options.options;
          for (var i = 0; i < option1.length; i++) {
            //if option  not empty
            if (option1[i].option_values.select_values != null) {
              // if select
              var element = {};
              element.value_id = option1[i].option_values.select_values[0].id;
              element.option_id = option1[i].id;
              this.options.push(element);
            } else {
              // if checkbox
              var checked = {};
              checked.id = option1[i].id;
              checked.value = false;
              this.checked.push(checked);
              //console.log(this.checked)
            }
          }
          //console.log(this.options)
        })
        .then(() => {
          this.loading = false;
          if (this.options.length != 0) {
            this.selectOption = true;
            /*// if we found select options
            var options = this.options;
            this.axios
              .post(
                this.$store.state.base_api_url +
                  this.$store.storeName +
                  "/product_price/" +
                  this.$route.params.id,
                {
                  options,
                },
                {
                  headers: {
                    "Accept-Language": this.$i18n.locale,
                  },
                }
              )
              .then((response) => {
                this.price = response.data.data.price;
                this.offer_price = response.data.data.new_price;
                this.quanity = response.data.data.quanity;
                this.Firstquanity = response.data.data.quanity;
                this.finalOptions = response.data.data.final_option;

                /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
                var checked_true = [];
                for (var i = 0; i < this.checked.length; i++) {
                  if (this.checked[i].value == true) {
                    checked_true.push(this.checked[i].id);
                  }
                }
                let S_name_full =
                  this.$store.storeName +
                  "/" +
                  this.$route.params.id +
                  this.finalOptions +
                  checked_true;

                let S_name2 =
                  this.$store.storeName +
                  "/" +
                  this.$route.params.id +
                  this.finalOptions;
                console.log("S_name " + S_name2);

                let qun = this.quanity;

                let AllCookies = this.$cookies.keys();
                for (let i = 0; i < AllCookies.length; i++) {
                  if (AllCookies[i].includes(S_name2)) {
                    // if (AllCookies[i] == S_name_full) {
                    //  console.log("hii i am there ");
                    //} else {
                    qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                    console.log(
                      this.$cookies.get(AllCookies[i]).quantity +
                        " quuuuun " +
                        qun
                    );
                    // }
                  }
                }
                this.quanity = qun;
              });*/
          } else if (this.options.length == 0) {
            // if we not found select options
            console.log(
              "hiiiiiiiiii updateall" + this.DBitems.product.sale_price
            );
            this.price = this.DBitems.product.sale_price;
            this.offer_price = this.DBitems.product.offer_price;
            this.min_quanity=1;
            if(this.DBitems.product.min_qnt)
            this.min_quanity = this.DBitems.product.min_qnt;

            this.max_quanity = this.DBitems.product.quantity;
            if(this.DBitems.product.max_qnt>0)
            this.max_quanity = this.DBitems.product.max_qnt;

            this.quanity = this.DBitems.product.quantity;
            this.Firstquanity = this.DBitems.product.quantity;

            /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
            var checked_true = [];
            for (var i = 0; i < this.checked.length; i++) {
              if (this.checked[i].value == true) {
                checked_true.push(this.checked[i].id);
              }
            }
            let S_name_full =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;

            let S_name2 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions;
            console.log("S_name " + S_name2);

            let qun = this.quanity;
            let min_qun = this.min_quanity;
            let max_qun = this.max_quanity;

            let AllCookies = this.$cookies.keys();
            for (let i = 0; i < AllCookies.length; i++) {
            if (AllCookies[i].includes(S_name2)) {
                
            console.log(
                   " qnt " + qun
                  +'<br> qnt_cookies '+this.$cookies.get(AllCookies[i]).quantity
                  +"<br> max_qnt "+max_qun
                   +"<br> max_qnt cookies "+max_qun
                );

                  this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
                qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
             if(min_qun<0)
             {
              min_qun=1;
             }
             if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 
                //this.$cookies.get(AllCookies[i]).quantity;
                console.log(
                  this.$cookies.get(AllCookies[i]).quantity + " quuuuuny  " + qun
                  +"max_qnt "+max_qun
                );
                // }
              }
              
            }
            this.quanity = qun;
            this.min_quanity = min_qun;
            this.max_quanity = max_qun;

            if(this.min_quanity>0)
         {
          this.counterVal=this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
         }
          }
        });
    },
    addToCart() {
      document.getElementById("#addToCart").style.display = "none";
      var checked_true = [];
      for (var i = 0; i < this.checked.length; i++) {
        if (this.checked[i].value == true) {
          checked_true.push(this.checked[i].id);
        }
      }
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_cart_item",
          {
            product_id: this.$route.params.id,
            total_price: this.price,
            offer_price: this.offer_price,
            quantity: Number(this.counterVal),
            old_qnt:this.old_qnt,
            final_option: this.finalOptions,
            option_check: checked_true
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          let product = null;
          if (response.data.code == "1") {
            // let products = new Map;
            let S_name1 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;
            if (this.$cookies.isKey(S_name1)) {
              /*console.log(
                "this.price " +
                  this.price +
                  "this.$cookies.get( S_name1 ).total_price" +
                  this.$cookies.get(S_name1).total_price
              );*/
              product = {
                product_id: this.$route.params.id,
                total_price:
                  this.price + this.$cookies.get(S_name1).total_price,
                offer_price:
                  this.offer_price + this.$cookies.get(S_name1).offer_price,
                quantity: Number(this.counterVal) + Number(this.$cookies.get(S_name1).quantity),
                quantityAll: this.$cookies.get(S_name1).quantityAll,
                max_quantity: this.$cookies.get(S_name1).max_quantity,
                min_quantity: this.$cookies.get(S_name1).min_quantity,
                final_option: this.finalOptions,
                option_check: checked_true,
                discount: this.DBitems.product.discount,
                note: this.note + " " + this.$cookies.get(S_name1).note
              };
            } else {
              console.log("hlooooooooooo ::::::::; " + this.Firstquanity);
              product = {
                product_id: this.$route.params.id,
                total_price: this.price,
                offer_price: this.offer_price,
                quantity: this.counterVal,
                quantityAll: this.Firstquanity,
                min_quantity: this.min_quanity,
                max_quantity: this.max_quanity,
                final_option: this.finalOptions,
                option_check: checked_true,
                discount: this.DBitems.product.discount,
                note: this.note
              };
            }
            //check if item is exist on cart or not
            //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
            // this.$cookies.remove('storeName')
            // products = this.$cookies.get(this.$store.storeName);
            //}
            //products.set(this.$route.params.id , product);
            let S_name = this.$store.storeName + "/";

            if (
              !this.$cookies.isKey(
                S_name +
                  product.product_id +
                  product.final_option +
                  product.option_check
              )
            ) {
              this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
            }

            this.$cookies.set(
              S_name +
                product.product_id +
                product.final_option +
                product.option_check,
              product
            );
            console.log(this.$cookies.keys());
          }
          this.validateMessage = response.data.message;
          /*this.$router.push({
            name: "shoppingCart",
            params: {
              storeName: this.$store.storeName,
            },
          });*/
          this.updateall();
          document.getElementById("#addToCart").style.display = "block";
          setTimeout(() => {
            // this.updateall();
            this.validateMessage = "";
          }, 3000);
        });
    },
    addEvalu() {
      if (
        this.$localStorage.get(this.$store.storeName) != null &&
        this.DBitems.product.is_rate == true
      ) {
        this.addeval = true;
      } else if (
        this.$localStorage.get(this.$store.storeName) != null &&
        this.DBitems.product.is_rate == false
      ) {
        if (this.$i18n.locale == "en") {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute(
              "data-hover",
              "You must purchase the product to add a review"
            );
        } else {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "يجب أن تشتري المنتج لإضافة تقييم");
        }
      } else {
        if (this.$i18n.locale == "en") {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "login to add evaluation");
        } else {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "سجل دخول لإضافة تقييم");
        }
      }
    },
    changeFav() {
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/favourite_product_change/" +
              this.$route.params.id,
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.fav = !this.fav;
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.loginMassage = "please Login";
          setTimeout(() => {
            this.loginMassage = "";
          }, 3000);
        } else {
          this.loginMassage = "يجب تسجيل الدخول";
          setTimeout(() => {
            this.loginMassage = "";
          }, 3000);
        }
      }
    },
    changeCheckbox(item, val) {
      if (val) {
        this.price = this.price + item.price * this.counterVal;
        if (this.offer_price != 0) {
          this.offer_price = this.offer_price + item.price * this.counterVal;
        }
      } else {
        /*(this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price))*/
        this.price = this.price - item.price * this.counterVal;
        if (this.offer_price != 0) {
          this.offer_price = this.offer_price - item.price * this.counterVal;
        }
        /*(this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price));*/
      }
    }
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.updateall();
      },
      immediate: true
    }
  }
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Hero {
  margin-top: 20px;
}

#Second {
  padding-top: 0px;
}

.pagination .page-item a {
  color: rgb(184 189 205);
  background-color: rgba(22, 22, 26, 0);
  border: none;
  text-decoration: underline;
  line-height: 0;
  border-radius: 100%;
  padding: 12px 10px 15px 10px;
}

.pagination .page-item a:hover {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
}

.myBtn {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-family: "Araboto-Bold";
}

.bi-star-fill {
  color: #ffcc00;
  font-size: 25px;
}

.button {
  cursor: pointer;
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 100%;
  padding: 10px 65px 15px 65px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  text-align: center;
}

.button:hover {
  background-color: #fff;
  color: #000;
}

h5 {
  font-size: 18px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
}

span {
  font-size: 18px;
  font-family: "Araboto-Light";
  color: var(--secondary-color);
}

select {
  border: 0;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(127, 137, 161, 0.25);
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 8px 15px 10px 15px;
}

textarea {
  border: 1px solid var(--secondary-color);
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(127, 137, 161, 0.25);
  border-radius: 10px;
}

.star {
  color: rgb(180, 180, 180);
  margin-left: 2px;
  margin-right: 2px;
}
.star-fill {
  color: #ffcc00;
  margin-left: 2px;
  margin-right: 2px;
}

label {
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
}

.hovertext {
  position: relative;

  color: #ffcc00;
  font-size: 30px;
  line-height: 30px;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  margin-top: 10px;
  background-color: rgb(239, 239, 239);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;
  font-size: 10px;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

.barndImg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: inline-block;
  box-shadow: 0 0 10px #8d8d8d;
  margin-left: 20px;
  margin-right: 20px;
}
.barndImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
}

/*@media (min-width: 2000px) {
  .collg6 {
    width: 15%;
  }
}*/
@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .section-title {
    text-align: center;
  }
  h2 {
    font-size: 30px;
  }
  h5 {
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  span {
    font-size: 16px;
  }

  label,
  select {
    font-size: 15px;
  }
  .myBtn {
    font-size: 12px;
  }
  .bi-star-fill {
    font-size: 18px;
  }
  .information,
  .orderInfo {
    width: 70% !important;
    margin-right: 15%;
    margin-left: 15%;
  }
}
@media (max-width: 575px) {
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  h2 {
    font-size: 12px;
  }
  h5 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  span {
    font-size: 12px;
  }
  .fav-img {
    width: 30px;
    height: 30px;
  }
  .myBtn,
  .star {
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;
    margin-bottom: 10px;
  }
  .star {
    justify-content: space-between !important;
    width: 100% !important;
    margin: 5px 0 10px 0 !important;
  }
  .star button {
    width: 30px !important;
    height: 30px !important;
    margin: 0px !important;
  }
  .star .hovertext {
    line-height: 20px;
  }
  .barndImg {
    width: 25px;
    height: 25px;
  }
}
@media (max-width: 390px) {
  .myBtn,
  .star {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
  }
  .information,
  .orderInfo {
    width: 90% !important;
    margin-right: 5%;
    margin-left: 5%;
  }
}
</style>
