<template>

  <div>
      <!-- Loader Start -->
      <div
         v-if="this.$store.state.loading_home"
        class="d-flex flex-row justify-content-center loading_card"
       
      >
        <!-- LOADER -->
        <div  style="position: fixed;
    top: 40%;
    left: 40%;" >
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
</div>
        <!-- Loader End -->
      <banners></banners>
      <sections></sections>
      <products_new></products_new>
      <products_saller></products_saller>
      <products_feautred></products_feautred>
      <sections_home></sections_home>
      <!-- <dowenloadApp></dowenloadApp> -->
      </div>
</template>
<script>

import banners from "../components/banners.vue";
import sections from "../components/list_sections.vue";
import products_new from "../components/list_products_new.vue";
import products_saller from "../components/list_products_saller.vue";
import products_feautred from "../components/list_products_feautred.vue";
import dowenloadApp from "../components/dowenloadApp.vue";
import sections_home from "../components/sections_home.vue";
import { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner } from "epic-spinners";


export default {
  components: {
    banners,
    sections,
    products_new,
    products_saller,
    products_feautred,
    dowenloadApp,
    sections_home,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner

   },
  data() {
    return {
      DBitems: null,
      loading: false,
      langChange:"",
      sort:'id',
      order:'desc',
      paginate:12,
    };
  },
  created() {
    this.loading=true;
    setTimeout(() => {
              this.loading = false
            }, 5000);
   this.$store.state.topbar='topbar_light';
   this.$store.state.navbar='navbar_light';
  },
  watch: {
    langChange() {
  },
},
  updated() {
    this.langChange=this.$i18n.locale
    this.langChange2=this.$i18n.locale


  },
  methods: {
  }

};
</script>
