var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"container-fluid relative mt-6 lg:mx-6 mx-3"},[(this.sections.length!=0)?_c('div',{staticClass:"section_grid grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6"},_vm._l((this.sections),function(item,index){return _c('div',{staticClass:"relative overflow-hidden group rounded-md shadow dark:shadow-slate-800"},[_c('router-link',{staticClass:"text-center",attrs:{"to":{
                       name: 'section',
                      params: { storeName: _vm.$store.storeName,
                       id: item.id  ,
                        name:item.name,
                        lang: _vm.$i18n.locale},
                              }}},[_c('img',{staticClass:"group-hover:scale-110 duration-500 section_list_image",staticStyle:{"background-color":"lightgrey"},attrs:{"src":item.icon,"alt":item.name,"loading":"lazy"}}),_c('span',{staticClass:"section_list_name bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium"},[_vm._v(" "+_vm._s(item.name))])])],1)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }