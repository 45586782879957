var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"600px"}},[(this.$store.state.langchange)?_c('div',[_vm._v(_vm._s(_vm.updatedata()))]):_vm._e(),(this.$store.state.apperance_slider==1)?_c('section',{staticStyle:{"padding-bottom":"0"},attrs:{"id":"hero"}},[_c('div',[_c('div',{staticClass:"row"},[(!_vm.loadingSlider)?_c('div',{staticClass:"col-lg-12",attrs:{"dir":"ltr"}},[_c('div',{staticClass:"heroCarousel"},[(_vm.sliders.length !=0)?_c('carousel',{attrs:{"per-page":1,"mouse-drag":false,"autoplay":"","loop":"","paginationEnabled":false}},_vm._l((_vm.sliders),function(item,idx){return _c('slide',{key:idx},[(item.value!='')?_c('img',{attrs:{"src":item.image},on:{"click":function($event){return _vm.sliderLink(item.value)}}}):_vm._e()])}),1):_c('div',[_c('img',{attrs:{"src":require("../../../public/assets/image/slide1.jpg")}})])],1)]):_vm._e()])])]):_vm._e(),(this.$store.state.apperance_sections==1&&_vm.sections)?_c('section',{attrs:{"id":"Second"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title d-flex flex-row justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t("sections")))]),_c('router-link',{attrs:{"to":{
              name: 'sections',
              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
            }}},[_c('p',[_vm._v(_vm._s(_vm.$t("viewAll")))])])],1),(!_vm.loadingSections)?_c('slider',{attrs:{"items":_vm.sections}}):_vm._e()],1)]):_vm._e(),_c('section',{attrs:{"id":"Fourth"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title d-flex flex-row justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t("Latest products")))]),_c('router-link',{attrs:{"to":{
              name: 'bestSeller',
              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
            }}},[_c('p',[_vm._v(_vm._s(_vm.$t("viewAll")))])])],1),(!_vm.loadingProducts_latest)?_c('div',{staticClass:"row mt-2"},_vm._l((this.products_latest),function(item,index){return _c('div',{key:index,staticClass:"col2 col-lg-3 col-6 mb-5"},[_c('card3',{attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id}})],1)}),0):_vm._e()])]),(!_vm.loadingOffers )?_c('div',[(_vm.offers.length!=0)?_c('section',{staticStyle:{"background-color":"#ebebeb"},attrs:{"id":"Third"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title d-flex flex-row justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t("offers")))]),_c('router-link',{attrs:{"to":{
              name: 'offers',
              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
            }}},[_c('p',[_vm._v(_vm._s(_vm.$t("viewAll")))])])],1),_c('slider-offers',{attrs:{"items":_vm.offers}})],1)]):_vm._e()]):_vm._e(),_c('section',{attrs:{"id":"Fourth"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title d-flex flex-row justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t("bestSeller")))]),_c('router-link',{attrs:{"to":{
              name: 'bestSeller',
              params: { storeName: this.$store.storeName },
            }}},[_c('p',[_vm._v(_vm._s(_vm.$t("viewAll")))])])],1),(!_vm.loadingProducts)?_c('div',{staticClass:"row mt-2"},_vm._l((this.products),function(item,index){return _c('div',{key:index,staticClass:"col2 col-lg-3 col-6 mb-5"},[_c('card3',{attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id}})],1)}),0):_vm._e()])]),(this.products_feutred.length >0)?_c('section',{attrs:{"id":"Fourth"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title d-flex flex-row justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t("fetured products")))]),_c('router-link',{attrs:{"to":{
              name: 'FeaturedProducts',
              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
            }}},[_c('p',[_vm._v(_vm._s(_vm.$t("viewAll")))])])],1),(!_vm.loadingProducts_feutred)?_c('div',{staticClass:"row mt-2"},_vm._l((this.products_feutred),function(item,index){return _c('div',{key:index,staticClass:"col2 col-lg-3 col-6 mb-5"},[_c('card3',{attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id}})],1)}),0):_vm._e()])]):_vm._e(),(!_vm.loadingBrands)?_c('div',[( _vm.brands!=null)?_c('section',{staticStyle:{"background-color":"#ebebeb"},attrs:{"id":"Third"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title d-flex flex-row justify-content-between"},[_c('h2',[_vm._v(_vm._s(_vm.$t("brands")))]),_c('router-link',{attrs:{"to":{
              name: 'brands',
              params: { storeName: this.$store.storeName,lang: _vm.$i18n.locale },
            }}},[_c('p',[_vm._v(_vm._s(_vm.$t("viewAll")))])])],1),_c('slider-brands',{attrs:{"items":_vm.brands}})],1)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }