<template> 
  <div style="min-height: 600px">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <!-- ======= Hero Section ======= -->
    <section id="hero" style="padding-bottom:0" v-if="this.$store.state.apperance_slider==1">
      <div>
        <!--style="max-width: 1180px">-->
        <div class="row">
          <div dir="ltr" class="col-lg-12" v-if="!loadingSlider">
            <div class="heroCarousel">
              <carousel
                v-if="sliders.length !=0"
                :per-page="1"
                :mouse-drag="false"
                autoplay
                loop
                :paginationEnabled="false"
              >
                <slide v-for="(item, idx) in sliders" :key="idx">
                  <img v-if="item.value!=''":src="item.image" @click="sliderLink(item.value)" />
                </slide>
              </carousel>
              <div v-else>
                <img src="../../../public/assets/image/slide1.jpg" />
              </div>
            </div>
            <!--<div class="heroText" v-if="!loading">
              <h2>{{ DBitems.name_ar }} | {{ DBitems.name_en }}</h2>
              <h3>{{ DBitems.title }}</h3>
              <p>{{ DBitems.description }}</p>
              <div class="information d-flex flex-row justify-content-center">
                <div class="info m-2 d-flex flex-row judtify-content-center">
                  <i class="bi bi-box"></i>
                  <div>
                    <number
                      tag="p"
                      ref="number1"
                      :from="0"
                      :to="DBitems.count_products"
                      :format="theFormat"
                      :duration="1"
                      easing="Power1.easeOut"
                    />
                    <span>{{ $t("productS") }}</span>
                  </div>
                </div>
                <div class="info m-2 d-flex flex-row judtify-content-center">
                  <i class="bi bi-person-circle"></i>
                  <div>
                    <number
                      tag="p"
                      ref="number2"
                      :from="0"
                      :to="DBitems.count_customers"
                      :format="theFormat"
                      :duration="1"
                      easing="Power1.easeOut"
                    />
                    <span>{{ $t("customer") }}</span>
                  </div>
                </div>
                <div class="info m-2 d-flex flex-row judtify-content-center">
                  <i class="bi bi-award"></i>
                  <div>
                    <number
                      tag="p"
                      ref="number2"
                      :from="0"
                      :to="DBitems.count_visit"
                      :format="theFormat"
                      :duration="1"
                      easing="Power1.easeOut"
                    />
                    <span>{{ $t("offer") }}</span>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </section>
    <!-- End hero -->
     <!-- ======= Second Section ======= -->
     <section id="Second" v-if="this.$store.state.apperance_sections==1&&sections">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("sections") }}</h2>
          <router-link
            :to="{
              name: 'sections',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
          >
            <p>{{ $t("viewAll") }}</p>
          </router-link>
        </div>
        <slider v-if="!loadingSections" :items="sections"></slider>
      </div>
    </section>
    <!-- End Second -->
<!-- ======= Fourth Section ======= -->
<section id="Fourth">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("Latest products") }}</h2>
          <router-link
            :to="{
              name: 'bestSeller',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
          >
            <p>{{ $t("viewAll") }}</p>
          </router-link>
        </div>
        <div class="row mt-2" v-if="!loadingProducts_latest">
          <div class="col2 col-lg-3 col-6 mb-5" v-for="(item, index) in this.products_latest" :key="index">
            <card3
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
            ></card3>
          </div>
        </div>
      </div>
    </section>
  

   

    <!-- ======= Third Section ======= -->
    <!--<section id="Third" style="background-color: #f7f8fb">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("offers") }}</h2>
          <router-link
            :to="{
              name: 'offers',
              params: { storeName: this.$store.storeName },
            }"
            ><p>{{ $t("viewAll") }}</p></router-link
          >
        </div>
        <vue-horizontal-list
          :items="offers"
          :options="options1"
          dir="ltr"
          v-if="!loadingOffers"
        >
          <template v-slot:nav-prev>
            <div>
              <img src="../../public/assets/image/icons/arrow.png" />
            </div>
          </template>

          <template v-slot:nav-next>
            <div>
              <img src="../../public/assets/image/icons/arrow.png" />
            </div>
          </template>

          <template v-slot:default="{ item }">
            <card2
              :product_id="item.product_id"
              :product_name="item.product_name"
              :image="item.image"
              :discount="item.discount"
              :rate="item.rate"
              :productid="item.id"
            ></card2>
          </template>
        </vue-horizontal-list>
      </div>
    </section>-->
    <!-- End Third -->

    <!-- ======= Third Section ======= -->
    <!--<section id="Third" style="background-color: #f7f8fb">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("offers") }}</h2>
          <router-link
            :to="{
              name: 'offers',
              params: { storeName: this.$store.storeName },
            }"
          >
            <p>{{ $t("viewAll") }}</p>
          </router-link>
        </div>
        <div id="app" class="menu-wrapper">
          <ul class="menu" ref="menu1">
            <li class="item" v-for="(item, index) in offers" :key="index">
              <card2
                :product_id="item.product_id"
                :product_name="item.product_name"
                :image="item.image"
                :discount="item.discount"
                :rate="item.rate"
                :productid="item.id"
              ></card2>
            </li>
          </ul>

          <div class="paddles">
            <button
              :class="
                $i18n.locale == 'ar'
                  ? ' right-paddle paddle'
                  : ' left-paddle paddle'
              "
              @click="scrollRight1"
            >
              <img src="../../public/assets/image/icons/arrow.png" />
            </button>

            <button
              :class="
                $i18n.locale == 'ar'
                  ? ' left-paddle paddle'
                  : ' right-paddle paddle'
              "
              @click="scrollLeft1"
            >
              <img src="../../public/assets/image/icons/arrow.png" />
            </button>
          </div>
        </div>
      </div>
    </section>-->
    <!-- End Third -->

    <!-- ======= Third Section ======= -->
    <div v-if="!loadingOffers ">
      <section v-if="offers.length!=0" id="Third" style="background-color: #ebebeb">
        <div class="container">
          <div class="section-title d-flex flex-row justify-content-between">
            <h2>{{ $t("offers") }}</h2>
            <router-link
              :to="{
              name: 'offers',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
            >
              <p>{{ $t("viewAll") }}</p>
            </router-link>
          </div>
          <slider-offers :items="offers"></slider-offers>
        </div>
      </section>
    </div>
    <!-- End Third -->

    <!-- ======= Fourth Section ======= -->
    <section id="Fourth">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("bestSeller") }}</h2>
          <router-link
            :to="{
              name: 'bestSeller',
              params: { storeName: this.$store.storeName },
            }"
          >
            <p>{{ $t("viewAll") }}</p>
          </router-link>
        </div>
        <div class="row mt-2" v-if="!loadingProducts">
          <div class="col2 col-lg-3 col-6 mb-5" v-for="(item, index) in this.products" :key="index">
            <card3
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
            ></card3>
          </div>
        </div>
      </div>
    </section>
    <!-- End Fourth -->


    <section v-if="this.products_feutred.length >0" id="Fourth">
      <div class="container">
        <div class="section-title d-flex flex-row justify-content-between">
          <h2>{{ $t("fetured products") }}</h2>
          <router-link
            :to="{
              name: 'FeaturedProducts',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
          >
            <p>{{ $t("viewAll") }}</p>
          </router-link>
        </div>
        <div class="row mt-2" v-if="!loadingProducts_feutred">
          <div class="col2 col-lg-3 col-6 mb-5" v-for="(item, index) in this.products_feutred" :key="index">
            <card3
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
            ></card3>
          </div>
        </div>
      </div>
    </section>

    <!-- ======= Fifth Section ======= -->
    <div v-if="!loadingBrands">
      <section v-if=" brands!=null" id="Third" style="background-color: #ebebeb">
        <div class="container">
          <div class="section-title d-flex flex-row justify-content-between">
            <h2>{{ $t("brands") }}</h2>
            <router-link
              :to="{
              name: 'brands',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
            >
              <p>{{ $t("viewAll") }}</p>
            </router-link>
          </div>
          <slider-brands :items="brands"></slider-brands>
        </div>
      </section>
    </div>
    <!-- End Fifth -->
  </div>
</template>
<script>
import card1 from "../components/CardStyle1.vue";
import card2 from "../components/CardStyle2.vue";
import card3 from "../components/CardStyle3.vue";
import VueHorizontalList from "vue-horizontal-list";
import { Carousel, Slide } from "vue-carousel";
import { off } from "process";
import Slider from "../components/slider.vue";
import SliderOffers from "../components/sliderOffers.vue";
import sliderBrands from "../components/sliderBrands.vue";

export default {
  name: "CarouselPage",
  components: {
    card1,
    card2,
    card3,
    VueHorizontalList,
    Carousel,
    Slide,
    Slider,
    SliderOffers,
    sliderBrands
  },
  data() {
    return {
      DBitems: null,
      sliders: null,
      sections: null,
      offers: null,
      brands: null,
      products: null,
      products_latest: null,
      products_feutred:null,
      loading: false,
      loadingSlider: false,
      loadingSections: false,
      loadingOffers: false,
      loadingBrands:false,
      loadingProducts: false,
      loadingProducts_feuterd:false,
      loadingProducts_latest: false,
      scrollAmount: 0,
      scrollAmount1: 0,

      images: [
        {
          src: "https://mdbootstrap.com/img/Photos/Slides/img%20(68).webp",
          src: "https://mdbootstrap.com/img/Photos/Slides/img%20(6).webp"
        }
      ]
      /*options: {
        responsive: [
          { end: 310, size: 1 },
          { start: 310, end: 576, size: 2 },
          { start: 576, end: 768, size: 4 },
          { start: 768, end: 992, size: 5 },
          { start: 992, end: 1150, size: 7 },
          { size: 8 },
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 24,
        },
        position: {
          start: 2,
        },
        autoplay: { play: false, repeat: true, speed: 2500 },
      },
      options1: {
        responsive: [
          { end: 400, size: 1 },
          { start: 400, end: 768, size: 2 },
          { start: 768, end: 1102, size: 3 },
          { size: 5 },
        ],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,

          // Because: #app {padding: 80px 24px;}
          padding: 24,
        },
        position: {
          start: 2,
        },
        autoplay: { play: false, repeat: true, speed: 2500 },
      },*/
    };
  },
  created() {

    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    /*this.loading = true;
    //////////////////////home//////////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => (this.DBitems = response.data.data.market))
      .then(() => (this.loading = false));*/
    //////////////////////sliders//////////////////////
    this.loadingSlider = true;
    if(this.$store.state.apperance_slider==1)
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/sliders")
      .then(response => (this.sliders = response.data.data.sliders))
      .then(() => (this.loadingSlider = false));

    //////////////////////sections//////////////////////
    this.loadingSections = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/sections",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.sections = response.data.data.sections;
        /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
      })
      .then(() => (this.loadingSections = false));
    //////////////////////offers//////////////////////
    this.loadingOffers = true;
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/offers", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        this.offers = response.data.data.offers;
        /*if (this.offers.size > 5) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.offers = this.offers.slice().reverse();
          }
        }*/
      })
      .then(() => (this.loadingOffers = false));
 //////////////////////Brands//////////////////////
 this.loadingBrands = true;
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/brands", {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      })
      .then(response => {
        if(response.data.data!=null){
        this.brands = response.data.data.brands;
        }
      })
      .then(() => (this.loadingBrands = false));

      
    //////////////////////products//////////////////////
    this.loadingProducts = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/products",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => (this.products = response.data.data.products))
      .then(() => (this.loadingProducts = false));




      this.loadingProducts = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/products/latest",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => (this.products_latest = response.data.data.products))
      .then(() => (this.loadingProducts_latest = false));
    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////


//fetrued
 //////////////////////products//////////////////////
 this.loadingProducts = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/products",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => (this.products = response.data.data.products))
      .then(() => (this.loadingProducts = false));




      this.loadingProducts_feutred = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/products/feutered",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => (this.products_feutred = response.data.data.products))
      .then(() => (this.loadingProducts_feutred = false));
    ////////////// , initializes a localStorage with a 'localStorage-id'.///////////////////



    //console.log("from home : " + this.$localStorage.id());
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    updatedata() {
      //console.log("hooooooooooooooo");
      /*this.loading = true;
      //////////////////////home//////////////////////
      this.axios
        .get(this.$store.state.base_api_url + this.$store.storeName + "/home", {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        })
        .then(response => (this.DBitems = response.data.data.market))
        .then(() => (this.loading = false));*/
      //////////////////////sliders//////////////////////
      this.loadingSlider = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sliders"
        )
        .then(response => (this.sliders = response.data.data.sliders))
        .then(() => (this.loadingSlider = false));

      //////////////////////sections//////////////////////
      this.loadingSections = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/sections",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.sections = response.data.data.sections;
          /*if (this.sections.size > 7) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.sections = this.sections.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loadingSections = false));
      //////////////////////offers//////////////////////
      this.loadingOffers = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/offers",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.offers = response.data.data.offers;
          /*if (this.offers.size > 5) {
          if (document.documentElement.getAttribute("dir") === "rtl") {
            this.offers = this.offers.slice().reverse();
          }
        }*/
        })
        .then(() => (this.loadingOffers = false));
        //////////////////////Brands//////////////////////
      this.loadingBrands = true;
          this.axios
            .get(this.$store.state.base_api_url + this.$store.storeName + "/brands", {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            })
            .then(response => {
              if(response.data.data!=null){
        this.brands = response.data.data.brands;
        }
            })
            .then(() => (this.loadingBrands = false));
      //////////////////////products//////////////////////
      this.loadingProducts = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/products",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => (this.products = response.data.data.products))
        .then(() => (this.loadingProducts = false));

      this.$store.state.langchange = false;
    },
    sliderLink(slidLink) {
      window.location.href = slidLink;
    }
    /* scrollLeft: function() {
      const menu = this.$refs.menu;
      if (this.$i18n.locale == "ar") {
        //if (this.scrollAmount > -(this.sections.length * 100) + 300) {
        if (this.scrollAmount >= -(this.sections.length * 100)) {
          menu.scrollTo({
            left: (this.scrollAmount -= 300),
            behavior: "smooth"
          });
        }
        console.log(this.scrollAmount);
      } else {
        if (this.scrollAmount <= this.sections.length * 100) {
          menu.scrollTo({
            left: (this.scrollAmount += 300),
            behavior: "smooth"
          });
          console.log(this.scrollAmount);
        }
      }
    },
    scrollRight: function() {
      const menu = this.$refs.menu;
      if (this.$i18n.locale == "ar") {
        if (this.scrollAmount < 0) {
          menu.scrollTo({
            left: (this.scrollAmount += 300),
            behavior: "smooth"
          });
        }
        console.log(this.scrollAmount);
      } else {
        if (this.scrollAmount > 0) {
          menu.scrollTo({
            left: (this.scrollAmount -= 300),
            behavior: "smooth"
          });
        }
        console.log("hii" + this.scrollAmount);
      }
    },*/
    /*scrollLeft1: function () {
      const menu = this.$refs.menu1;
      if (this.$i18n.locale == "ar") {
        if (this.scrollAmount1 > -(this.offers.length * 200)) {
          menu.scrollTo({
            left: (this.scrollAmount1 -= 600),
            behavior: "smooth",
          });
        }
        console.log(this.scrollAmount1);
      } else {
        if (this.scrollAmount1 < this.offers.length * 200) {
          menu.scrollTo({
            left: (this.scrollAmount1 += 600),
            behavior: "smooth",
          });
          console.log(this.scrollAmount1);
        }
      }
    },
    scrollRight1: function () {
      const menu = this.$refs.menu1;
      if (this.$i18n.locale == "ar") {
        if (this.scrollAmount1 < 0) {
          menu.scrollTo({
            left: (this.scrollAmount1 += 600),
            behavior: "smooth",
          });
        }
        console.log(this.scrollAmount1);
      } else {
        if (this.scrollAmount1 > 0) {
          menu.scrollTo({
            left: (this.scrollAmount1 -= 600),
            behavior: "smooth",
          });
        }
        console.log("hii" + this.scrollAmount1);
      }
    },*/
  }
};
</script>
<style scoped>
.menu-wrapper {
  position: relative;
  /*max-width: 310px;height: 100px;*/
  margin: 1em auto;
  border: 0;
  background: transparent;
  overflow-x: hidden;
  overflow-y: hidden;
}

.menu-wrapper .menu {
  /*height: 120px;*/
  /*box-sizing: border-box;*/
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}

.menu-wrapper .item {
  display: inline-block;
  /*width: 100%;*/
  height: 100%;
  /* outline: 1px dotted gray;*/
  padding: 1em;
  box-sizing: border-box;
}

.menu-wrapper .paddle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 3em;
  background-color: transparent;
  border: 0;
}
.menu-wrapper .left-paddle {
  left: 0;
}
.menu-wrapper .right-paddle {
  right: 0;
  transform: rotate(180deg);
}
.menu-wrapper .hidden {
  display: none;
}

.menu-wrapper .print {
  margin: auto;
  max-width: 500px;
}

.menu-wrapper span {
  display: inline-block;
  width: 100px;
}
.paddle img {
  box-shadow: 0 0 7px 0 rgb(134, 134, 134);
  border-radius: 50px;
}
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  margin-top: 0px;
  padding-top: 0;
  background-image: url("../../../public/assets/image/background.png");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 80%;
  /*margin-right: 15px !important;
    margin-left: 15px !important;
    overflow: hidden;
    position: relative;
    background: transparent;
    padding: 0;*/
}

#hero img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

#hero .heroCarousel {
  margin-top: 0;
  /* height: 390px;
  width: 100%;
  max-width: 500px;*/
  aspect-ratio: 1200 / 400;
  border-radius: 0;
  padding: 0;
}

.VueCarousel-inner {
  border-radius: 0px !important;
}

.heroText {
  background-color: #ebebeb;
  width: 100%;
  padding: 20px;
}
#hero h2 {
  font-family: "Araboto-Black";
  font-size: 25px;
  text-align: center;
  color: var(--primary-color);
}

#hero h3 {
  font-family: "Araboto-Black";
  font-size: 18px;
  margin-top: 20px;
  color: #000;
  text-align: center;
}

#hero p {
  font-family: "Araboto-Medium";
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  color: var(--secondary-color);
}
#hero .info {
  margin-top: 0 !important;
}
#hero .info p,
#hero .info .number {
  color: #222;
  font-family: "Araboto-Medium";
  font-size: 25px;
}
#hero .info span {
  color: var(--secondary-color);
  font-family: "Araboto-Medium";
  font-size: 16px;
}

#hero img {
  object-fit: cover;
  border-radius: 0;
}

#hero .info .bi {
  font-size: 60px;
  margin: 0 20px;
  color: var(--primary-color);
  /*text-shadow: 0 0px 10px #000000;*/
}

.section-title {
  padding-bottom: 10px;
}
/*@media (min-width: 2000px) {
  #hero p {
    font-size: 25px !important;
    width: 70% !important;
  }
  .col2 {
    flex: 0 0 auto ;
    width: 16.66666667% !important;
  }
}

@media (min-width: 1400px) {

  #hero {
    margin-top: 120px;
  }

  #hero .heroCarousel {
    margin-top: 25px;
    height: 490px;
    width: 100%;
    border-radius: 25px;
    padding: 0;
  }

  #hero h2 {
    font-size: 60px;
  }

  #hero h3 {
    font-size: 40px;
  }

  #hero p {
    font-size: 20px;
    width: 80%;
  }
  #hero .info p {
    font-size: 34px;
  }
  #hero .info span {
    font-size: 20px;
  }
}*/

/*@media (min-width: 1024px) {
  #hero p {
    width: 90%;
  }
}*/

@media (max-width: 992px) {
  #hero .carousel-container {
    margin-bottom: 15px;
  }
  #hero h2 {
    font-size: 22px;
  }
  #hero h3 {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  #hero p {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    line-height: 20px !important;
    font-size: 14px !important;
    margin-bottom: 10px !important;
  }
  #hero .info .bi {
    font-size: 40px;
    margin: 0 10px;
  }
  #hero .info p {
    /* line-height: 10px;*/
    margin-top: 10px;
  }
  #hero .information {
    justify-content: center !important;
  }
  #hero .button {
    font-size: 16px;
  }
  #hero {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .card {
    width: 100%;
  }
}

@media (max-width: 630px) {
  #hero h2 {
    font-size: 21px;
  }
  #hero h3 {
    font-size: 16px;
    margin-bottom: 20px !important;
  }
  #hero p {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }
  #hero .info .bi {
    font-size: 30px;
  }
  #hero .button {
    font-size: 14px;
  }
  /* #hero .heroCarousel {
    height: 290px;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }*/
}

@media (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
  #hero h3 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  #hero p {
    font-size: 12px !important;
    margin-bottom: 3px !important;
  }
  #hero .button {
    width: 180px;
    font-size: 11px;
  }
  .section-title p {
    font-size: 16px;
  }
  .card {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 400px) {
  #hero h2 {
    font-size: 15px;
  }
  #hero h3 {
    font-size: 10px;
    margin-bottom: 7px !important;
  }
  #hero p,
  #hero .info span {
    font-size: 8px !important;
  }

  #hero .button {
    font-size: 7px;
  }
  /*#hero .heroCarousel {
    height: 200px;
  }*/
}
</style>
