var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"container_order"}},[(this.$store.state.langchange)?_c('div',[_vm._v(_vm._s(_vm.updatedata()))]):_vm._e(),_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.$t('myOrders')))]),_c('profile_header'),_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"text-align":"center","margin-top":"4%"}},[_c('button',{class:' order_prev button ' + (_vm.activeTab ? '' : 'active'),on:{"click":function($event){_vm.activeTab ? (_vm.activeTab = !_vm.activeTab) : (_vm.activeTab = _vm.activeTab)}}},[_vm._v(_vm._s(_vm.$t('CurrentOrders')))]),_c('button',{class:'order_prev button prev_orders ' + (_vm.activeTab ? 'active' : ''),on:{"click":function($event){!_vm.activeTab ? (_vm.activeTab = !_vm.activeTab) : (_vm.activeTab = _vm.activeTab)}}},[_vm._v(_vm._s(_vm.$t('PreviousOrders')))]),_c('hr',{staticClass:"center_align",staticStyle:{"width":"50%"}})]),(_vm.loading)?_c('div',{staticClass:"d-flex flex-row justify-content-center",staticStyle:{"margin-top":"20%","margin-left":"45%"}},[_c('div',[_c('hollow-dots-spinner',{attrs:{"animation-duration":1000,"dot-size":35,"dots-num":3,"color":"var(--primary-color)"}})],1)]):_vm._e(),(!_vm.loading)?_c('div',{style:(_vm.activeTab ? 'display:none' : 'display: block'),attrs:{"id":"tab1","data-aos":"fade-up"}},[(_vm.DBitems.length != 0)?_c('div',{staticClass:"row"},_vm._l((_vm.DBitems),function(item,index){return _c('div',{key:index,staticClass:"col-lg-4 col-md-6 col-12"},[_c('div',{staticStyle:{"border":"1px solid #c9c9c9","margin-top":"2%","margin-bottom":"2%"},on:{"click":function($event){return _vm.$router.push({
            name: 'invoice'
            , params: { storeName: _vm.$store.storeName, lang: _vm.$i18n.locale, id: item.id }
          })}}},[_c('p',{staticClass:"invoice_number"},[_vm._v(" #"+_vm._s(item.invoice_id))]),_c('div',{staticClass:"order_meta"},[_c('div',[_c('span',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(" "+_vm._s(_vm.$t('total'))+":")]),_c('span',{staticClass:"order_state"},[_vm._v(_vm._s(item.total_price * _vm.$store.state.rate)+" "+_vm._s(_vm.$store.state.UnitPrice))])]),_c('div',[_c('span',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(_vm._s(_vm.$t('status'))+":")]),_c('span',{staticClass:"order_state"},[_vm._v(_vm._s(item.state))])]),_c('div',[_c('span',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(_vm._s(_vm.$t('Time and Date'))+":")]),_c('span',{staticClass:"order_date"},[_vm._v(_vm._s(item.time))])]),_c('button',{staticClass:"butt_green but_order center_align"},[_vm._v(_vm._s(_vm.$t('show order')))])])])])}),0):_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/assets/images/empty.png"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")]),(this.count > 1)?_c('div',{staticClass:"d-flex flex-row justify-content-center mt-5"},[_c('b-pagination',{attrs:{"total-rows":_vm.count,"per-page":_vm.pageSize,"prev-text":_vm.$t('prev'),"next-text":_vm.$t('next'),"pills":""},on:{"change":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()]):_vm._e(),(!_vm.loading1)?_c('div',{staticStyle:{"display":"none"},style:(_vm.activeTab ? 'display:block' : 'display:none'),attrs:{"id":"tab2","data-aos":"fade-up"}},[(_vm.DBitems1.length != 0)?_c('div',{staticClass:"row"},_vm._l((_vm.DBitems1),function(item,index){return _c('div',{key:index,staticClass:"col-lg-6 col-md-6 col-12"},[_c('div',{staticStyle:{"border":"1px solid #c9c9c9","margin-top":"2%","margin-bottom":"2%"},on:{"click":function($event){return _vm.$router.push({
            name: 'invoice'
            , params: { storeName: _vm.$store.storeName, lang: _vm.$i18n.locale, id: item.id }
          })}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-4 col-6"},[_c('div',{staticStyle:{"width":"160px","height":"225px","margin":"14% 14%"}},[_c('img',{staticStyle:{"width":"150px","object-fit":"cover","padding-top":"9%"},attrs:{"src":_vm.$store.state.logo}})])]),_c('div',{staticClass:"col-lg-8 col-md-8 col-6",staticStyle:{"padding-bottom":"2%","padding-top":"2%"}},[_c('p',{staticClass:"invoice_number"},[_vm._v(" #"+_vm._s(item.invoice_id))]),_c('p',{staticClass:"invoice_number"},[_vm._v("x"+_vm._s(item.count_items))]),_c('div',{staticClass:"order_meta"},[_c('h6',[_vm._v(_vm._s(_vm.$store.state.storeName))]),_c('h6',{staticStyle:{"color":"var(--primary-color)","font-size":"13px","font-weight":"400"}},[_vm._v(" "+_vm._s(item.total_price * _vm.$store.state.rate)+" "+_vm._s(_vm.$store.state.UnitPrice))])]),_c('div',[_c('span',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(_vm._s(_vm.$t('status'))+":")]),_c('span',{staticClass:"order_state"},[_vm._v(_vm._s(item.state))])]),_c('div',[_c('span',{staticStyle:{"color":"var(--primary-color)"}},[_vm._v(_vm._s(_vm.$t('Time and Date'))+":")]),_c('span',{staticClass:"order_state"},[_vm._v(_vm._s(item.date))])]),_c('button',{staticClass:"butt_green but_order center_align"},[_vm._v(_vm._s(_vm.$t('show order')))])])])])])}),0):_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/assets/images/empty.png"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('empty'))+" ")]),(this.count1 > 1)?_c('div',{staticClass:"d-flex flex-row justify-content-center mt-5"},[_c('b-pagination',{attrs:{"total-rows":_vm.count1,"per-page":_vm.pageSize1,"prev-text":_vm.$t('prev'),"next-text":_vm.$t('next'),"pills":""},on:{"change":_vm.handlePageChange1},model:{value:(_vm.page1),callback:function ($$v) {_vm.page1=$$v},expression:"page1"}})],1):_vm._e()]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }