<template>
    <div style="min-height:600px">
        <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

     <!-- Start Hero -->
     <section class="
     relative 
     table
     w-full 
     items-center py-36 
     bg-[url('theme8/images/hero/pages.jpg')] 
     bg-top
      bg-no-repeat
       bg-cover
       " style="background-image:url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/227/slider/2024/08/1723628771_1723717856.jpg')">
            <div class="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 class="mb-3 text-4xl leading-normal tracking-wider font-semibold text-white">{{ $t('search') }}</h3>

                    <p class="text-slate-400 text-lg max-w-xl mx-auto">{{ $t("Resultsfor") }}: {{ this.$route.params.word }}</p>
                </div><!--end grid-->
            </div><!--end container-->
            
            <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul class="tracking-[0.5px] mb-0 inline-block">
                    <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><router-link :to="{
                              name: 'home',
                             params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                     }">{{ $t('home') }}
                                     </router-link>
                                     </li>
                    <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                    <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">{{ $t('search') }}</li>
                </ul>
            </div>

              
        </section><!--end section-->

        <div class="container-fluid relative mt-6 lg:mx-6 mx-3">
            <div class="grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6">
                <div v-if="this.sections.length!=0" 
                v-for="(item, index) in this.sections" 
                class="relative overflow-hidden group
                 rounded-md shadow dark:shadow-slate-800">
                    <router-link  :to="{
                       name: 'section',
                      params: { storeName: $store.storeName,
                       id: item.id  ,
                        name:item.name,lang: $i18n.locale},
                              }" 
                        class="text-center">
                        <img :src="item.image" 
                        class="group-hover:scale-110 duration-500 section_list_image" 
                        :alt="item.name">
                        <span class="section_list_name bg-white dark:bg-slate-900
                         group-hover:text-orange-500 py-2
                          px-6 rounded-full shadow dark:shadow-gray-800
                           absolute bottom-4 mx-4 text-lg font-medium">
                           {{ item.name }}</span>
                    </router-link>
                </div><!--end content-->


            </div><!--end grid-->
            <div style="text-align:center" v-if="this.sections.length!=0" >
            <button class="loadmorebutton" @click="LoadMoreSections">
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
              </div>
        </div><!--end container-->

             <!-- Start -->
     <section class="relative md:py-24 py-16">
            <div class="container relative">
                <div class="grid items-end md:grid-cols-2 mb-6">
                    <div class="md:text-start text-center">
                        <h5 class="font-semibold text-3xl leading-normal mb-4">{{ $t('products') }}</h5>
                        <p class="text-slate-400 max-w-xl">
                        {{ $t('Shop the latest products from the most popular items') }}</p>
                    </div>

                    <div class="md:text-end hidden md:block">
                        <router-link 
                        :to="{
                              name: 'products',
                             params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                     }"
                         class="text-slate-400 hover:text-orange-500">
                            {{ $t('See More Items') }}
                            
                            <i class="mdi mdi-arrow-right"></i></router-link>
                    </div>
                </div><!--end grid-->

                <div 
                v-if="this.products.length!=0"
                class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
                  <product 
                   v-for="(item, index) in this.products"
        :key="index" 
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
        
        >
  
          </product>

       
                    <!--end content-->

                </div><!--end grid-->
                <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>

            </div><!--end container-->
          <div style="text-align:center" v-if="this.products.length>12">
            <button class="loadmorebutton" @click="LoadMoreProducts" >
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
              </div>
        </section><!--end section-->
    

        <section class="relative md:py-24 py-16">
            <div class="container relative">
                <div class="grid items-end md:grid-cols-2 mb-6">
                    <div class="md:text-start text-center">
                        <h5 class="font-semibold text-3xl leading-normal mb-4">{{ $t('offers') }}</h5>
                        <p class="text-slate-400 max-w-xl">
                        {{ $t('Shop the latest products from the most popular items') }}</p>
                    </div>

                    <div class="md:text-end hidden md:block">
                        <router-link 
                        :to="{
                              name: 'offers',
                             params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                     }"
                         class="text-slate-400 hover:text-orange-500">
                            {{ $t('See More Items') }}
                            
                            <i class="mdi mdi-arrow-right"></i></router-link>
                    </div>
                </div><!--end grid-->

                <div v-if="this.offers.length!=0"
                 class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
                  <product v-for="(item, index) in this.offers"
        :key="index" 
              :name="item.name"
              :description="item.description"
              :is_favorit="item.is_favorit"
              :image="item.image"
              :old_price="item.old_price"
              :new_price="item.new_price"
              :productid="item.id"
              :rate="item.rate"
              :count_view="item.count_views"
              :is_option="item.is_option"
              :is_offer="item.is_offer"
               :unit="item.unit"
               :new="item.is_new"
               :fetuered="item.is_fetured"
               :discount="item.discount"
               :qnt="item.qnt"
               :min_qnt="item.min_qnt"
               :max_qnt="item.max_qnt"
        
        >
  
          </product>
                    <!--end content-->

                </div><!--end grid-->
                
                <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/pet_shop/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>

            </div><!--end container-->
            <div style="text-align:center" v-if="this.offers.length!=0">
            <button class="loadmorebutton" @click="LoadMoreOffers">
                {{ $t("LoadMore") }}
                <img
                  v-if="loadMoreIcon"
                  src="https://etrolley.net/store/assets/image/icons/loader.gif"
                  style="width: 30px"
                />
              </button>
              </div>
        </section><!--end section-->
    

    </div>
</template>
<script>
import product from "../components/one_product_list.vue";

export default {
  components: { product},
  data() {
    return {
      loading: false,
      DBitems: null,

      sections: null,
      offers: null,
      products: null,
      loadingSections: false,
      loadingOffers: false,
      loadingProducts: false,

      pageSections: 1,
      allPageSections: 0,
     
      pageOffers: 1,
      allPageOffers: 0,
     

      pageProducts: 1,
      allPageProducts: 0,
    

      loadMoreIcon: false,
    };
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.updatedata();
      },
      immediate: true,
    },
  },
  created() {
    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark';

    //////////////////////sections//////////////////////
    document.getElementById('search_form').classList.add('hidden');
    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
    //////////////////////sections//////////////////////
    this.loadingSections = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/search_section",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.sections = response.data.data.sections;
        this.allPageSections = response.data.meta.last_page;
        this.pageSizeSection=response.data.meta.per_page;

      })
      .then(() => (this.loadingSections = false));

    //////////////////////offers//////////////////////
    this.loadingOffers = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/theme5/search_offer",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.offers = response.data.data.offers;
        this.allPageOffers = response.data.meta.last_page;
        this.pageSizeOffers=response.data.meta.per_page;

        //this.countOffers = response.data.meta.total;
      })
      .then(() => (this.loadingOffers = false));

    //////////////////////products//////////////////////
    this.loadingProducts = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/theme4/search_product",
        {
          word: this.$route.params.word,
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.products = response.data.data;
        this.allPageProducts = response.data.meta.last_page;
        //this.countProducts = response.data.meta.total;
        this.pageSizeProducts=response.data.meta.per_page;

      })
      .then(() => (this.loadingProducts = false));
  },
  methods: {
    updatedata() {
      //console.log("hooooooooooooooo");
      //////////////////////sections//////////////////////
      this.loadingSections = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/search_section",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.sections = response.data.data.sections;
        })
        .then(() => (this.loadingSections = false));

      //////////////////////offers//////////////////////
      this.loadingOffers = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/search_offer",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.offers = response.data.data;
        })
        .then(() => (this.loadingOffers = false));

      //////////////////////products//////////////////////
      this.loadingProducts = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/theme4/search_product",
          {
            word: this.$route.params.word,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.products = response.data.data;
        })
        .then(() => (this.loadingProducts = false));

      this.$store.state.langchange = false;
    },
    LoadMoreSections() {
      if (this.pageSections < this.allPageSections) {
        //this.loadingSections = true;
        this.loadMoreIcon = true;
        this.pageSections++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_section?page=" +
              this.pageSections,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.sections.length; i++) {
              this.sections.push(response.data.data.sections[i]);
            }
            this.loadMoreIcon = false;
          });
        //.then(() => (this.loadingSections = false));
      }
    },
 
    LoadMoreOffers() {
      if (this.pageOffers < this.allPageOffers) {
        this.loadMoreIcon = true;
        this.pageOffers++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_offer?page=" +
              this.pageOffers,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.offers.length; i++) {
              this.offers.push(response.data.data.offers[i]);
            }
            this.loadMoreIcon = false;
          });
      }
    },
    LoadMoreProducts() {
      if (this.pageProducts < this.allPageProducts) {
        this.loadMoreIcon = true;
        this.pageProducts++;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/search_product?page=" +
              this.pageProducts,
            {
              word: this.$route.params.word,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < response.data.data.products.length; i++) {
              this.products.push(response.data.data.products[i]);
            }
            this.loadMoreIcon = false;
          });
      }
    },
  },
};
</script>
